import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { resetPasswordValidation } from '../../../validation';
import { Formik, Form, FormikHelpers } from 'formik';
import { resetPassword } from '../../../services';
import { useNavigate,useLocation,Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { StyledH3 } from '../../../components/styledcomponents';


function SetNewPaasword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation()
  const userId = location.state?.userId;
  const initialValues = { password: '',confirm_password: '', };
  const onSubmit = async (e: any, actions: FormikHelpers<{
    password: string;
    confirm_password: string;
  }>) => {
    const res = await resetPassword(e,userId);
    if (res)
      navigate('/login');
      toast.success("Password changed")
  };


  return (
    <Formik initialValues={initialValues} validationSchema={resetPasswordValidation} onSubmit={onSubmit}>
      {
        ({ isSubmitting, errors, getFieldProps, touched, isValid }) => (<Form>
          <div className='surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden' >
            <div className="flex flex-column align-items-center justify-content-center">
              <div style={{ borderRadius: '56px', padding: '0.3rem', }}>
                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                  {/* <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">FMT</div>
                    <span className="text-600 font-medium">Set New Password</span>
                  </div> */}
                  <StyledH3 className="fmt-forgot-password-title">Set New Password</StyledH3>
                  <hr/>
                  <div>
                  <div className="flex flex-column gap-2 ">
                      <label htmlFor="password1" className="block text-900 font-medium text-xl mb-2">
                        New Password*
                      </label>
                      <Password id="password1" aria-describedby='password-help' className={`w-full ${touched.password && errors.password ? 'p-invalid' : ''}`} inputClassName='w-full p-3 md:w-30rem' feedback={false}  {...getFieldProps(('password'))} name='password' toggleMask />
                      {touched.password && errors.password && <ErrorMessage message={errors.password} id="password-help" />}
                    </div>
                    <div className="flex flex-column gap-2 ">
                      <label htmlFor="password1" className="block text-900 font-medium text-xl mb-2">
                        Re-Enter Password*
                      </label>
                      <Password id="password2" aria-describedby='password-help' className={`w-full ${touched.confirm_password && errors.confirm_password ? 'p-invalid' : ''}`} inputClassName='w-full p-3 md:w-30rem' feedback={false}  {...getFieldProps(('confirm_password'))} name='confirm_password' toggleMask />
                      {touched.confirm_password && errors.confirm_password && <ErrorMessage message={errors.confirm_password} id="password-help" />}
                    </div>
                    <div className="flex align-items-center justify-content-between mb-5 gap-5">
                    <Link to="/login" className="font-medium no-underline ml-auto text-right cursor-pointer mt-3" style={{ color: 'var(--primary-color)' }}>Back to Login ?</Link>

                    </div>
                    <Button label={isSubmitting ? '' : 'Reset Password'} className="w-full p-2 text-md" type='submit' loading={isSubmitting} />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>)
      }
    </Formik>

  );
}

export default SetNewPaasword;
