import React, { useState , useEffect,useRef} from 'react';
import { t } from "i18next";
import DataGridV3 from "../../../../../components/DataGridV3/DataGridV3";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import EcommerceRateModal from './ecommerceRateModal';
import { Button } from "primereact/button"; // Ensure you're using PrimeReact Button component
import './ecommerceRateModal.css';
import { EcommRatemultipleDelete, EcommRatelist, EcommRateget  } from "../../../../../services/ecommAdminPanelService/EcommRateService";
import { privateDecrypt } from 'crypto';
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import EcommViewModal from './ecommViewModal';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from "react-i18next";


// Typing for the state
interface SourceOption {
  name: string;
  value: string;
}

const EcommerceRates: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]); // Update type as needed
  const [reload, setReload] = useState<boolean>(true);
  const [totalRecords, setTotal] = useState<number>(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectSource, setSelectSource] = useState<string>(""); // Better to type as string or specific value
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [visibleView, setVisibleView] = useState<boolean>(false);
  const [id, setId] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedType, setSelectedType] = useState('');
  // const [selectedItem, setSelectedItem] = useState('');
  const [allDataFetched,setAllDataFetched] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [values, setValues] = useState<any>("");
  const [EcommDetails, setEcommDetails] = useState<any>(null);
  const [selectedEcommRateId, setSelectedEcommRateId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { t,i18n } =useTranslation();
  const [debouncedSelectedType, setDebouncedSelectedType] = useState('');
  const inputRef = useRef<HTMLInputElement>(null)
  
  useEffect(() => {   
    const timer = setTimeout(() => {     
      setDebouncedSelectedType(selectedType); // Update the debounced value  
      }, 1000); // debounce delay (e.g., 1000ms or 1 second)
      
       return () => clearTimeout(timer); // Clean up the timer 
  }, [selectedType]);
  
  const onCreateOrUpdate = (t: boolean, i: string) => {
    setId(i);   
    setVisible(t);
    setReload(true);
  };

  const handleReload = () => {
    setSelectedDate(null)
    setSelectedType("")
    setPage(0)
    setTableData([]); 
    getAllData()
  }

  function formatDateToDDMMYYYY(isoDate: string): string {
    let date = new Date(isoDate);
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const deleteByIdList = (i: any) => {
    EcommRatemultipleDelete(i).then((res) => {
      if (res) {
        toast.success(t("Records deleted successfully"));
        // getSerialNo();
        setReload(true);
      } else {
        toast.error(t("Failed to delete records"));
      }
    });
  };

  // const onScroll = async (): Promise<void> => {
  //   if (!loading &&!allDataFetched) {
  //     setPage((prev) => prev + 1);
  //   }
  // };


  const getAllData = () => {
    const filters: any = {
      type: debouncedSelectedType,
      date: selectedDate,
      // item: selectedItem,
      // minPrice: selectedPrice?.min,
      // maxPrice: selectedPrice?.max,
    };
    EcommRatelist( (page + 1).toString(),limit.toString(),filters).then((res: any) => {
      const formattedData = res.data.res.map((item: any, index: number) => ({
        ...item,
        key: item.id || index,
        type:item.type,
        rate:item.rate,
        date: formatDateToDDMMYYYY(item.date),
      }));      
      setTableData(formattedData);
      setTotal(res.data.totalPages);
      setReload(false)
    });
  };

  const details = async (id: string) => {
    try {
      const response = await EcommRateget(id);
      if (response && response.data && response.data.data) {
        const EcommerceData = response.data.data;
        const formattedEcommerceData = {
          ...EcommerceData,
          date: formatDateToDDMMYYYY(EcommerceData.date),
        };
        setValues(formattedEcommerceData);
        setViewDetails(true);
      } else {
        console.log("No data found for this ID");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    if (reload) {
      getAllData();
    }
  }, [reload]);

  useEffect(() => {
    getAllData();
  }, [page, limit,selectedDate,debouncedSelectedType]);

  const getPaginationProps = (p: string, l: string) => {
    setPage(parseInt(p, 10));
    setLimit(parseInt(l, 10));
  };

  useEffect(() => {
    if(values) {
      const EcommerceDetails = {
        date:values.date || "N/A",
        // adminName:values.adminName || "N/A",
        // item:values.item || "N/A",
        item:values.type || "N/A",
        rate:values.rate || "N/A",
        unit:values.unit || "N/A",
        comments:values.comments || "N/A",
      };
      setEcommDetails(EcommerceDetails);
    }
  },[values]
);

const handleView = (rateId: string) => {
  setSelectedEcommRateId(rateId);
  setVisibleView(true);
};
  const columns = [
    { field: "rateSlNo", header: t("Sl No") },
    { field: "date", header: t("Date") },
    // { field: "adminName", header: "Admin Name" },
    // { field: "item", header: "Item" },
    { field: "type", header: t("Item") },
    { field: "rate", header: t("Item Rate") },
    { field: "unit", header: t("Units") },
  ];

  const deleteDialogFooter = (
    <>
      <Button
        label={t("No") || "No"}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label={t("Yes") || "Yes"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteByIdList(id);
          setDeleteDialog(false);
        }}
      />
    </>
  );

  const generatePriceOptions = (start: number, end: number, step: number) => {
    const options = [];
    for (let i = start; i <= end; i += step) {
      options.push({ name: i.toString(), value: i.toString() });
    }
    return options;
  };
  
  const priceOptions = generatePriceOptions(100, 10000, 100); // Generate prices from 100 to 10000 in steps of 100
    
  const EcommFilter = () => {
    return (
      <div>
        <Button
          icon="pi pi-filter"
          tooltip={t("Filter") || "Filter"}
          onClick={() => setShowFilters(!showFilters)}
          className="mr-3 p-button-rounded"
          style={{ backgroundColor: "#11B3CF" }}
        />
        {showFilters && (
          <div style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "10px" }}>
            <Calendar
              placeholder={t("Date") as string}
              dateFormat="dd-mm-yy"
              showIcon
              value={selectedDate}
              className="mr-2"
              onChange={(e) => setSelectedDate(e.value)}
              style={{height:"40px", width:"200px"}}
            />
            <InputText
              type="text"
              placeholder={t("Item") as string}
              className="mr-2"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              style={{height:"40px"}}
              ref={inputRef}
            />
          </div>
        )}
      </div>
    );
  };
  

  return (
    <>
      <div className='ml-auto flex justify-between gap-10'>
        <h1>{t("Rates")}</h1>
      </div>

      <DataGridV3
        tittle={t("")}
        data={tableData}
        columns={columns}
        totalRecords={totalRecords}
        paginationProps={getPaginationProps}
        // onScroll={onScroll}
        onViewDeatils={details}
        reload={false}
        filter={EcommFilter}
        setReload={handleReload}
        deleteByIdList={deleteByIdList}
        extraAction={() => {}}
        addNew={true}
        showActions={selectedRows.length > 0}
        setSelectedRows={setSelectedRows}
        onCreateOrEdit={onCreateOrUpdate}
      />

      <EcommerceRateModal
        setVisible={setVisible}
        visible={visible}
        updateTableData={() => setReload(true) } // Temporary empty function or implement
        setReload={(val: boolean) => setReload(val)} // Temporary empty function or implement
        id={id}     
      />

      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={t("Confirm")}
        modal
        onHide={() => setDeleteDialog(false)}
        footer={deleteDialogFooter}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {!id ? (
            <span>{t("Are you sure you want to delete the selected items?")}</span>
          ) : (
            <span>{t("Are you sure you want to delete?")}</span>
          )}
        </div>
      </Dialog>

      {viewDetails && (
        <EcommViewModal
          setVisible={setViewDetails}
          visible={viewDetails}
          details={EcommDetails || {}}
          title={t("Rate Details")}
        />
      )}
    </>
  );
}
export default EcommerceRates;
