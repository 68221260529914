import { axiosInstance } from '../interceptors';


export const save = async (agent_interest: any) => {
    return await axiosInstance.post(`/master/chitSettings/addInterestRate`, {agent_interest});
};
export const list = async () => {
    return await axiosInstance.get(`/chits/list/agentInetrestrates`);
};
export const update = async ( agent_interest: number,id:string) => {
    return await axiosInstance.put(`/master/chitSettings/updateInterestRate`,{agent_interest,id})
  };