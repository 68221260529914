import * as Yup from 'yup';


export const loginValidation = Yup.object().shape({
    user_name: Yup.string().email("Invalid email").required('Username Required'),
    password: Yup.string().required("Password Required")
});


export const forgotpasswordValidation =Yup.object().shape({
    user_name: Yup.string().email("Invalid_email").required('Username Required'),
});

export const otpValidation =Yup.object().shape({   
    otp: Yup.number()
    .min(4, 'Invalid OTP')
    .required('OTP is required'),
});


export const resetPasswordValidation = Yup.object().shape({
    password: Yup.string().required('Password is required')
    .min(12, 'Password must be at least 12 characters long')
    .matches(/[a-zA-Z]/, 'Password must contain at least 1 letter')
    .matches(/[0-9]/, 'Password must contain at least 1 number'),
  
    confirm_password: Yup.string().required('Re-enter password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match')
  });
