import React from 'react';
import { Button } from 'primereact/button';
import '../../assets/fonts/fonts.css'
function CancelButton({ label, onClose }:any) {

  return (
    <Button
      label={label ? label : ''}
      type="button"
      icon="pi pi-times"
      className="p-button-danger"
      style={{fontFamily: 'Raleway',fontStyle:"normal",fontWeight:"500px"}}
      onClick={onClose}
    />
  );
}

export default CancelButton;