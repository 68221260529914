import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import {  Form, Formik } from "formik";
import { serialNoSave } from "../../../../validation";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { useEffect } from 'react';
import { serialSvc } from "../../../../services";
import { t } from "i18next";
const SerialNoModal = ({ setVisible, visible = false, id,updateTableData }: ISerialNoModal) => {
    const ini = {
        serial_no_type: '',
        prefix: '',
        last_no: '',
        length: '',
    };
    const [serialId, setSerialId] = useState(id);
    const [initialValues, setInitialValues] = useState(ini);
    const [serialNoType, setSerialNoType] = useState<any>('')
    let error: any = null;

    useEffect(() => {
        serialSvc.serialNoTypeList().then(res => {
    const options = res.data.map((item: { name: any; value: any; }) => ({
        name: item.name,
        value: item.value,
      }));
      setSerialNoType(options);
            

      });
    }, []);
    const onSubmit = async (e: any, actions: any) => {
        if (serialId) {
            await serialSvc.update(serialId,e);
            toast.success("Updated");
            updateTableData();
            onClose();
        }
        else {
            await serialSvc.save(e);
            toast.success("Created");
            updateTableData();          
            onClose()

        }
        actions.setSubmitting(false);
    };

    useEffect(() => {
        setSerialId(id);
        if (id && visible) {
            serialSvc.get(id).then(res => setInitialValues({ ...res.data }));
        }
    }, [id, visible]);


    const onClose = () => {
        setVisible(false); setSerialId(undefined); setInitialValues(ini);
    };
    
    return (
        <Dialog header={!id ? t("Create Serial No") as string : t("Update Serial No") as string} visible={visible} maximizable style={{ width: '50vw' }} onHide={onClose}>

            <Formik enableReinitialize initialValues={initialValues} validationSchema={serialNoSave} onSubmit={onSubmit} >
                {
                    ({ isSubmitting, errors, getFieldProps, touched, setFieldValue, values, isValid }) => (

                        <Form>

                            <div className="grid p-fluid mt-3">

                                <div className="field col-12 md:col-5">
                                    <span className="p-float-label">
                                        <Dropdown options={serialNoType} {...getFieldProps(('serial_no_type'))} value={values.serial_no_type}
                                            onChange={(e) => setFieldValue('serial_no_type', e.value)} optionLabel="name"
                                            optionValue="value" />
                                        <label htmlFor="serial_no_type">{t("Serial No Type")}</label>
                                    </span>
                                    {touched.serial_no_type && errors.serial_no_type && <ErrorMessage message={errors.serial_no_type} id="serial_no_type" />}
                                </div>

                                <div className="field col-12 md:col-5">
                                    <span className="p-float-label">
                                        <InputText type="text" id="prefix" {...getFieldProps(('prefix'))} name='prefix' />
                                        <label htmlFor="Prefix"  >{t("Prefix")}</label>
                                    </span>
                                    {touched.prefix && errors.prefix && <ErrorMessage message={errors.prefix} id="prefix" />}
                                </div>
                                

                                <div className="field col-12 md:col-5">
                                    <span className="p-float-label">
                                        <InputText id="last_no" {...getFieldProps(('last_no'))} name='last_no' />
                                        <label htmlFor="last_no">{t("Last No")}</label>
                                    </span>
                                    {touched.last_no && errors.last_no && <ErrorMessage message={errors.last_no} id="last_no" />}
                                </div>
                                <div className="field col-12 md:col-5">
                                    <span className="p-float-label ">
                                        <InputText id="length" {...getFieldProps(('length'))} name='length' />
                                        <label htmlFor={"length"}>{t("Length")}</label>
                                    </span>
                                    {touched.length && errors.length && <ErrorMessage message={errors.length} id="length" />}
                                </div>
                            </div>

                            <div className="flex flex-wrap justify-content-end gap-3 mt-3">
                                <Button label={t("Cancel") + ''} icon="pi pi-times" className="p-button-danger p-button-raised" onClick={onClose} />

                                <Button type="submit" label={id ? t('Update') as string : t('Save') as string} icon="pi pi-check" className="p-button-success p-button-raised"  style={{background: '#11B3CF'}}  loading={isSubmitting} />
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
};

export default SerialNoModal;



interface ISerialNoModal {
    visible: boolean;
    setVisible(val: boolean): void;
    id: string | undefined;
    updateTableData:any;
}