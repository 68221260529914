import { request } from 'http';
import { axiosInstance } from '../interceptors';
import IVehicleRate from '../../interfaces/models/IVehicleRate';

export const RateSave = async (req: any) => {
    return await axiosInstance.post(`inventory/rates`, req);
};

export const Ratelist = async ( page: string,limit: string,filter:any={}) => {
    return await axiosInstance.get(`inventory/rates/list`,{
      params: {
        page,
        limit,
        ...filter,
      }
    });
};



export const Rateupdate = async ( request: IVehicleRate) => {
    return await axiosInstance.put(`inventory/rates/update`,request);
};




export const RateDelete = async (id:string) => {
    return await axiosInstance.put(`inventory/delete/${id}`);
};

// export const getByIdVehicle = async (id:string) => {
//     return await axiosInstance.get(`inventory/vehicle/${id}`);
// };

export const Rateget= async (id:string) => {
    return await axiosInstance.get(`/inventory/rates/getById/${id}`);}


    export const RatemultipleDelete = async (idHistory: string[] | number[]) => {
        console.log('IDs to delete:', idHistory); // Log the idHistory before sending the request
       
        try {
          const response = await axiosInstance.post(`/inventory/rates/multipleDelete`, idHistory);
          console.log("??????????????????????",idHistory);
          
          return response;
        } catch (error) {
          console.error('Error deleting multiple records:', error);
          throw error; // Re-throw the error to handle it elsewhere if needed
        }
      };