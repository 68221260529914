import * as Yup from 'yup';

export const vehicleSave = Yup.object().shape({
    make: Yup.string()
        .matches(/^[A-Za-z]+$/, 'Only contain characters')
        .required('Required'),
    model: Yup.string()
        .required('Required'),
    type: Yup.string()
        .matches(/^[A-Za-z]+$/, 'Only contain characters')
        .required("Required"),
    // plate: Yup.string()
    //     .required("Required"),
    registration_number: Yup.string()
        .matches(/^[A-Za-z0-9]+$/, 'Characters and numbers are allowed')
        .required('Required'),
    pollution_expiry: Yup.date()
       .required('Insurance expiry date is required')
       .typeError('Required'), // Optional: To provide a clearer error message for invalid date formats

    insurance_expiry: Yup.date()
        .required('Insurance expiry date is required')
        .typeError('Required'), // Optional: To provide a clearer error message for invalid date formats
      
    colour: Yup.string()
        .matches(/^[A-Za-z]+$/, 'Only contain characters')
        .required('Required'),
        // vehicle_id: Yup.string()
        // .matches(/^\d+$/, 'Only contain numbers')
        // .required('Required'),
        price: Yup.string()
        .matches(/^\d+$/, 'Only contain numbers')
        .required('Required'),
        seat_capacity: Yup.string()
        .matches(/^\d+$/, 'Only contain numbers')
        .required('Required'),
});
