export const model = [
    {
        items: [{ label: 'dashboard', icon: 'pi pi-home', to: '/app' }],
        separator: false
    },
    {
        to: '/app',
        items: [
            {
                label: 'Admin Panel',
                icon: 'pi pi-fw pi-cog',
                items: [
                    {
                        label: 'Loan',
                        icon: 'pi pi-fw pi-cog',
                        to: '/pages/timeline',
                        items: [
                            {
                                label: 'Serial No',
                                icon: 'pi pi-fw pi-list',
                                to: '/app/serialno'
                            },
                            {
                                label: 'Interest Rate',
                                icon: 'pi pi-fw pi-money-bill',
                                to: '/app/interestsettings'
                            },
                            {
                                label: 'Loan Settings',
                                icon: 'pi pi-fw pi-calculator',
                                to: '/app/loansettings'
                            },
                            {
                                label: 'Chit Commission',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/app/chitsettings'
                            }
                        ]
                    },
                    {
                        label: 'Rent',
                        icon: 'pi pi-fw pi-cog',
                        to: '/pages/timeline',
                        items: [
                            {
                                label: 'Vehicle',
                                icon: 'pi pi-car',
                                to: '/app/vehicle'
                            },
                            {
                                label: 'Rates',
                                icon: 'pi pi-fw pi-money-bill',
                                to: '/app/rates'
                            },
                            {
                                label: 'Driver',
                                icon: 'pi pi-fw pi-user',
                                to: '/app/driver'
                            }
                        ]
                    },
                    {
                        label: 'Ecommerce',
                        icon: 'pi pi-fw pi-cog',
                        to: '/pages/timeline',
                        items: [
                            // {
                            //     label: 'Rates',
                            //     icon: 'pi pi-fw pi-money-bill',
                            //     to: '/app/ecommerce/rates'
                            // },
                            {
                                label: 'Supplier',
                                icon: 'pi pi-fw pi-money-bill',
                                to: '/app/ecommerce/supplier'
                            },
                        ]
                    }
                ]
            }
        ]
    }
];
