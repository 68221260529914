import { request } from 'http';
import { axiosInstance } from '../interceptors';
import IVehicle from '../../interfaces/models/IVehicle';

export const save = async (req: any) => {
    return await axiosInstance.post(`inventory/vehicle`, req);
};

export const vehiclelist = async (limit: string, page: string) => {
  return await axiosInstance.get(`inventory/vehicle/vehiclelist?page=${page}&limit=${limit}`);
};

export const list = async (page: string,limit: string, filter:any={}) => {
    return await axiosInstance.get(`inventory/vehicle/list`,{
      params: {
        page,
        limit,
        ...filter,
      }
    });
};


export const update = async (  request: IVehicle) => {
    return await axiosInstance.put(`inventory/update`,request);
};


export const Delete = async (id:string) => {
    return await axiosInstance.put(`inventory/delete/${id}`);
};

// export const getByIdVehicle = async (id:string) => {
//     return await axiosInstance.get(`inventory/vehicle/${id}`);
// };

export const get= async (id:string) => {
    return await axiosInstance.get(`/inventory/getById/${id}`);}


    export const multipleDelete = async (idHistory: string[] | number[]) => {
        console.log('IDs to delete:', idHistory); // Log the idHistory before sending the request
       
        try {
          const response = await axiosInstance.post(`/inventory/multipleDelete`, idHistory);
          console.log("??????????????????????",idHistory);
          
          return response;
        } catch (error) {
          console.error('Error deleting multiple records:', error);
          throw error; // Re-throw the error to handle it elsewhere if needed
        }
      };