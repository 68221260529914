import { axiosInstance } from './interceptors';


export const get = async (id:string) => {
    return await axiosInstance.get(`/users/getuser/${id}`);
};
export const getDetails = async (id:string) => {
    return await axiosInstance.get(`/users/getEmployee/${id}`);
};

export const update = async (id: string, request: any) => {
    return await axiosInstance.put(`/users/${id}`,request)
};

export const preferenceApp = async (id: any, url: any) => {
    return await axiosInstance.put(`/users/addPreference?user_id=${id}&app_preference=${url}`)
};
