// import {takeLatest,put} from 'redux-saga/effects'


// function* toggleSidebarSaga(){
//     while (true){
//     yield takeLatest ('TOGGLE_SIDEBAR',
//     function*(){
//         yield put({type:'TOGGLE_SIDEBAR'});
//     });
// }
// }



// export default toggleSidebarSaga;

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_USER_PERMISSIONS_REQUEST,
  fetchUserPermissionsSuccess,
  fetchUserPermissionsFailure,
} from '../actions/actions';
import { permissionsSvc } from '../../services';

function* fetchUserPermissions(action) {
  try {
    const userId = action.payload;
    const response = yield call(permissionsSvc.getUserPermissions,userId);
    const permissions = response.data
    yield put(fetchUserPermissionsSuccess(permissions));
  } catch (error) {
    yield put(fetchUserPermissionsFailure(error));
  }
}


function* watchFetchUserPermissions() {
  yield takeLatest(FETCH_USER_PERMISSIONS_REQUEST, fetchUserPermissions);
}

export default function* rootSaga() {
    yield call(watchFetchUserPermissions);
}
