import * as Yup from 'yup';

export const newPasswordSave = Yup.object({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
        .required('New password is required')
        .min(12, 'Password must be at least 12 characters')
        .matches(/[a-zA-Z]/, 'Password must contain at least 1 letter')
        .matches(/[0-9]/, 'Password must contain at least 1 number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least 1 special character'),

    confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});