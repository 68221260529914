import React, { useState , useEffect } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { ratesSave } from "../../../../validation/adminPanelValidation/VehicleRate";
import { vehiclelist } from "../../../../services/rentAdminPanelService/AddVehicleService";
import { RateSave,Rateupdate ,Rateget } from "../../../../services/rentAdminPanelService/RateService";
import IVehicleRate from "../../../../interfaces/models/IVehicleRate";
import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";

interface IVehicleModel {
  visible: boolean;
  setVisible(val: boolean): void;
  id: string | undefined;
  updateTableData: () => void;
  setReload(val: boolean): void
}

export default function AddVehicleModal({ setVisible, visible = false,id,updateTableData,setReload }: IVehicleModel) {
  const initialValues: IVehicleRate = {
    date: new Date(),
    vehicleMake: "",
    vehicleId: "",
    registration_number:"",
    baseRate: 0,
    kmRate: 0,
    maxKmPerDay: 0,
    comments: "",
    vehicleRateSlNo:"",
  };

  const [vehicleOptions, setVehicleOptions] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<IVehicleRate>(initialValues);
  const { t,i18n } =useTranslation();

  useEffect(() => {
    if (id) {
        Rateget(id)
        .then((res) => {
          if (res.data && res.data.data) {
              setFormValues(res.data.data)
          }
        })
        .catch((error) => {
          toast.error(t("Failed to load vehicle data."));
        });
    } else if (!id && visible) {
      setFormValues(initialValues);
    }
  }, [id]);

  const getSerialNo = async (searchTerm: string) => {
    try {
        const res = await vehiclelist("10", "1"); // Fetch data from API
        if (res && res.data && res.data.res) {
            const currentDate = new Date(); // Get current date
 
            const formattedData = res.data.res.map((item: any) => ({
                id: item.vehicle_id,
                make: item.make,
                registration_number: item.registration_number,
                insurance_expiry: new Date(item.insurance_expiry), // Ensure it's a Date object
            }));
 
            // Filter out vehicles with expired insurance
            const validInsuranceData = formattedData.filter(
                (item: any) => item.insurance_expiry >= currentDate
            );
 
            // Apply the 'make' filter
            const filteredData = validInsuranceData.filter((item: any) =>
                item.make.toLowerCase().includes(searchTerm.toLowerCase())
            );
 
            console.log("Filtered Data:", filteredData); // Debugging
            setVehicleOptions(filteredData);
        } else {
            console.warn("No data received from API");
        }
    } catch (error) {
        console.error("Error fetching vehicle data:", error);
    }
};

  const onClose = () => {
    setFormValues(initialValues)
    setVisible(false);
  };

const onSubmit = async (values: IVehicleRate, formikHelpers: FormikHelpers<IVehicleRate>) => {
    const res = id ? await Rateupdate(values) : await RateSave(values);
    toast.success(t("Success"));
    setVisible(false);
    formikHelpers.resetForm()
    setFormValues(initialValues)
    updateTableData()
    setReload(true)

};

  const DialogFooter = (
    <div className="flex justify-content-end gap-3">
      <Button label={id ? t("Update") || "Update": t("Save") ||"Save"} icon="pi pi-check" className="p-button-success" type="submit" />
      {/* <Button label="Clear All" icon="pi pi-refresh" className="p-button-secondary" type="reset" /> */}
      <Button 
        type="button" 
        label={t("Cancel") || "Cancel"} 
        onClick={() => setVisible(false)} />
    </div>
  );

  return (
    <Dialog 
    header={<span style={{ fontWeight: 'bold' }}>{id ? t("Edit Rate") : t("Add Rate")}</span>}
    visible={visible} 
    headerStyle={{ textAlign: "center", color: "black" }}
    style={{ width: "60vw" }} onHide={onClose}>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={ratesSave}
        onSubmit={(values, actions) => {
          console.log("Formik Submission Triggered:", values); // Debugging
          onSubmit(values, actions);
        }}
      >
        {({ values, handleChange, setFieldValue, errors, touched }) => (
          <Form>
            <div className="grid p-fluid ">
               {/* Date Field */}
               <div className="field col-12 md:col-4">
                <label htmlFor="date">{t("Date")}</label>
                <Calendar
                  id="date"
                  value={values.date ? new Date(values.date) : null}
                  onChange={(e) => setFormValues({
                    ...values,
                    date: e.value instanceof Date ? e.value : new Date()})}
                  // dateFormat="dd-mm-yy" // Specify the date format
                  showIcon
                  placeholder={t("Select a Date") || "Select a Date"}
                />
                {errors.date && touched.date && (
                  <ErrorMessage message={errors.date} id="date-error" />
                )}
              </div>
            </div>
            <div className="grid p-fluid ">
              {/* Vehicle Make Input */}
              <div className="field col-12 md:col-4">
                <label htmlFor="vehicleMake">{t("Vehicle Make")}</label>
                <AutoComplete
                  id="vehicleMake"
                  value={values.vehicleMake}
                  suggestions={vehicleOptions.map((v) => v.make)}
                  completeMethod={(e) => getSerialNo(e.query)}
                  onChange={(e) => {
                    // Allow typing but ensure the customer name is valid on change.
                    setFieldValue("vehicleMake", e.value);
                    // Optionally, clear out the details if the name changes to something invalid
                    setFieldValue("vehicleId", "");
                    setFieldValue("registration_number", "");
                }}                  onSelect={(e) => {
                    setFieldValue("vehicleMake", e.value);
                    const selectedVehicle = vehicleOptions.find((v) => v.make === e.value);
                    if (selectedVehicle) {
                      setFieldValue("vehicleId", selectedVehicle.id);
                      setFieldValue("registration_number", selectedVehicle.registration_number); // Set registration number
                    }
                  }}
                  
                  placeholder={t("Search Vehicle Make...") || "Search Vehicle Make..."}
                />
                {errors.vehicleMake && touched.vehicleMake && (
                  <ErrorMessage message={errors.vehicleMake} id="vehicleMake-error" />
                )}
              </div>

              {/* Vehicle ID (Read-only) */}
              <div className="field col-12 md:col-4">
                <label htmlFor="vehicleId">{t("Vehicle ID")}</label>
                <InputText id="vehicleId" value={values.vehicleId} readOnly placeholder={t("Vehicle ID")||"Vehicle ID"} />
                {errors.vehicleId && touched.vehicleId && (
                  <ErrorMessage message={errors.vehicleId} id="vehicleId-error" />
                )}
              </div>

              {/* REGISTRATION NO (Read-only) */}
              <div className="field col-12 md:col-4">
                <label htmlFor="registration_number">{t("Registration Number")}</label>
                <InputText id="registration_number" value={values.registration_number} readOnly placeholder={t("Registration Number") || "Registration Number"} />
                {errors.registration_number && touched.registration_number && (
                  <ErrorMessage message={errors.registration_number} id="registration_number-error" />
                )}
              </div>
            </div>

            <div className="grid p-fluid">
              {/* Base Rate */}
              <div className="field col-12 md:col-4">
                <label htmlFor="baseRate">{t("Base Rate")}</label>
                <InputText
                  id="baseRate"
                  value={values.baseRate.toString()} // Convert number to string
                  onChange={(e) => setFieldValue("baseRate", parseFloat(e.target.value) || 0)} // Convert back to number
                  placeholder={t("Enter Base Rate...") || "Enter Base Rate..."}
                />
                {errors.baseRate && touched.baseRate && (
                  <ErrorMessage message={errors.baseRate} id="baseRate-error" />
                )}
              </div>
              {/* KM Rate */}
              <div className="field col-12 md:col-4">
                <label htmlFor="kmRate">{t("KM Rate")}</label>
                <InputText
                  id="kmRate"
                  value={values.kmRate.toString()} // Convert number to string
                  onChange={(e) => setFieldValue("kmRate", parseFloat(e.target.value) || 0)} // Convert back to number
                  placeholder={t("Enter KM Rate...") || "Enter KM Rate..."}
                />
                {errors.kmRate && touched.kmRate && (
                  <ErrorMessage message={errors.kmRate} id="kmRate-error" />
                )}
              </div>

              {/* Max KM/Day */}
              <div className="field col-12 md:col-4">
                <label htmlFor="maxKmPerDay">{t("Max KM/Day")}</label>
                <InputText
                  id="maxKmPerDay"
                  value={values.maxKmPerDay.toString()} // Convert number to string
                  onChange={(e) => setFieldValue("maxKmPerDay", parseFloat(e.target.value) || 0)} // Convert back to number
                  placeholder={t("Enter Max KM Per Day...")||"Enter Max KM Per Day..."}
                />
                {errors.maxKmPerDay && touched.maxKmPerDay && (
                  <ErrorMessage message={errors.maxKmPerDay} id="maxKmPerDay-error" />
                )}
              </div>
            </div>

            <div className="grid p-fluid">
              {/* Comments */}
              <div className="field col-12">
                <label htmlFor="comments">{t("Comments")}</label>
                <InputText
                  id="comments"
                  value={values.comments}
                  onChange={handleChange}
                  placeholder={t("Add comments...")||"Add comments..."}
                />
                {errors.comments && touched.comments && (
                  <ErrorMessage message={errors.comments} id="comments-error" />
                )}
              </div>
            </div>

            <div className="flex justify-content-center">{DialogFooter}</div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
