import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { Formik, FormikHelpers ,Form } from "formik";
import { vehicleSave } from "../../../../validation/adminPanelValidation/vehicle";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { save, update, get } from "../../../../services/rentAdminPanelService/AddVehicleService";
import IVehicle from "../../../../interfaces/models/IVehicle";
import { Dropdown} from "primereact/dropdown";
import { useTranslation } from "react-i18next";

interface IVehicleModel {
  visible: boolean;
  setVisible(val: boolean): void;
  id: string | undefined;
  updateTableData: () => void;
  setReload(val:boolean):void

}

export default function AddVehicleModal({
  setVisible,
  visible = false,
  id,
  updateTableData,
  setReload
}: IVehicleModel) {
  const initialValues: IVehicle = {
    make: "",
    model: "",
    vehicle_id: "",
    pollution_expiry: null,
    insurance_expiry: null,
    price: "",
    registration_number: "",
    colour: "",
    type: "",
    seat_capacity: "",
  };
  
  const [formValues, setFormValues] = useState<IVehicle>(initialValues);
  const [values,setValues] = useState([])
  const { t,i18n } =useTranslation();

  useEffect(() => {
    if (id && visible) {
      get(id)
        .then((res) => {
          if (res.data && res.data.data) {
            const vehicleData = res.data.data;
            setFormValues({
              ...vehicleData,
              pollution_expiry: vehicleData.pollution_expiry ? new Date(vehicleData.pollution_expiry) : "",
              insurance_expiry: vehicleData.insurance_expiry ? new Date(vehicleData.insurance_expiry) : "",
            });
          }
        })
        .catch(() => {
          toast.error(t("Failed to load vehicle data."));
        });
    } else if (visible) {

      setFormValues(initialValues);
    }
  }, [id, visible]);

  const onClose = () => {
    setFormValues(initialValues);
    setVisible(false);

  };

  const onSubmit = async (values: IVehicle, actions: FormikHelpers<IVehicle>) => {
    try {
      const res = id ? await update(values) : await save(values);
      toast.success(t("Success"));
      setVisible(false);
      actions.resetForm();
      updateTableData();
      setFormValues(initialValues);
      setReload(true)
    } catch (error) {
      console.error("API Error:", error);
      toast.error(t("An error occurred. Please try again."));
    }
  
  };

  const seatOption = 
    [
      {label:"2",value:2},
      {label:"3",value:3},
      {label:"4",value:4},
      {label:"5",value:5},
      {label:"6",value:6},
      {label:"7",value:7},
      {label:"8",value:8},
      {label:"9",value:9},
      {label:"10",value:10}

    ]
  

  const handleChange = (e: { target: { id: string; value: any } }) => {
    const { id, value } = e.target;
    setValues({
      ...values,
      [id]: value,  // Update the value for the specific field
    });
  };
  
  return (
    <Dialog
    header={<span style={{ fontWeight: 'bold' }}>{id ? t("Edit Vehicle") : t("Add Vehicle")}</span>}
    visible={visible}
    headerStyle={{ textAlign: "center", color: "black" }}
    style={{ width: "60vw" }}
    onHide={onClose}
  >      <Formik enableReinitialize initialValues={formValues} validationSchema={vehicleSave} onSubmit={onSubmit}>
        {({ isSubmitting, handleChange, handleSubmit, setFieldValue, values,errors,touched }) => (
          <Form onSubmit={handleSubmit}>
              <div className="p-fluid grid">
                {/* Existing fields */}
                {/* Make */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="make" value={values.make} onChange={handleChange} />
                    <label htmlFor="make">{t("Make")}</label>
                  </span>
                  {touched.make && errors.make && <ErrorMessage message={errors.make} id="make" />}

                </div>
                {/* Model */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="model" value={values.model} onChange={handleChange} />
                    <label htmlFor="model">{t("Model")}</label>
                  </span>
                  {touched.model && errors.model && <ErrorMessage message={errors.model} id="model" />}

                </div>
                {/* Type */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="type" value={values.type} onChange={handleChange} />
                    <label htmlFor="type">{t("Type")}</label>
                  </span>
                  {touched.type && errors.type && <ErrorMessage message={errors.type} id="type" />}

                </div>
                {/* Plate Number */}
                {/* <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="plate" value={values.plate} onChange={handleChange} />
                    <label htmlFor="plate">Plate Number</label>
                  </span>
                  {touched.plate && errors.plate && <ErrorMessage message={errors.plate} id="plate" />}

                </div> */}
                {/* Registration Number */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="registration_number" value={values.registration_number} onChange={handleChange} />
                    <label htmlFor="registration_number">{t("Registration Number")}</label>
                  </span>
                  {touched.registration_number && errors.registration_number && <ErrorMessage message={errors.registration_number} id="registration_number" />}

                </div>
                {/* Pollution Expiry */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <Calendar
                      id="pollution_expiry"
                      value={values.pollution_expiry}
                      onChange={(e) => setFieldValue("pollution_expiry", e.value)}
                      showIcon
                    />
                    <label htmlFor="pollution_expiry">{t("Pollution Expiry")}</label>
                  </span>
                  {touched.pollution_expiry && errors.pollution_expiry && <ErrorMessage message={errors.pollution_expiry} id="pollution_expiry" />}

                </div>
                {/* Insurance Expiry */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <Calendar
                      id="insurance_expiry"
                      value={values.insurance_expiry}
                      onChange={(e) => setFieldValue("insurance_expiry", e.value)}
                      showIcon
                    />
                    <label htmlFor="insurance_expiry">{t("Insurance Expiry")}</label>
                  </span>
                  {touched.insurance_expiry && errors.insurance_expiry && <ErrorMessage message={errors.insurance_expiry} id="insurance_expiry" />}

                </div>
                {/* Additional Fields */}
                {/* Price */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="price" value={values.price} onChange={handleChange} />
                    <label htmlFor="price">{t("Price")}</label>
                  </span>
                  {touched.price && errors.price && <ErrorMessage message={errors.price} id="price" />}

                </div>
                {/* Colour */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="colour" value={values.colour} onChange={handleChange} />
                    <label htmlFor="colour">{t("Colour")}</label>
                  </span>
                  {touched.colour && errors.colour && <ErrorMessage message={errors.colour} id="colour" />}

                </div>
                {/* Vehicle ID */}
                {/* <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText id="vehicleSlNo" value={values.vehicleSlNo} onChange={handleChange} />
                    <label htmlFor="vehicleSlNo">Vehicle ID</label>
                  </span>
                  {touched.vehicleSlNo && errors.vehicleSlNo && <ErrorMessage message={errors.vehicleSlNo} id="vehicleSlNo" />}

                </div> */}
                {/* Seat Capacity */}
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    {/* Dropdown for seat capacity */}
                    <Dropdown
                      id="seat_capacity"
                      value={values.seat_capacity}
                      options={seatOption}  // Array of seat capacities
                      onChange={handleChange}
                      optionLabel="label"
                      placeholder="Select Seat Capacity"
                      className="w-full"  // Optional: Make the dropdown full width
                    />
                    <label htmlFor="seat_capacity">{t("Seat Capacity")}</label>
                  </span>
                  {touched.seat_capacity && errors.seat_capacity && <ErrorMessage message={errors.seat_capacity} id="seat_capacity" />}

                </div>
              </div>
              <div className="flex justify-content-end mt-4">
              {/* <Button label="Clear All" icon="pi pi-refresh" className="p-button-secondary  mr-2" type="reset" /> */}
                <Button
                  type="submit" 
                  label={id ? t("Update")||"Update" : t("Save")||"Save"} 
                  className="p-button-success mr-2" 
                  disabled={isSubmitting} 
                />
                <Button 
                  type="button"
                  label={t("Cancel")||"Cancel"} 
                  onClick={() => setVisible(false)}
                />  
              </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

