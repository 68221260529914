
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import i18n from "../../../i18n/config";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { Menu } from "primereact/menu";


interface Country {
    name: string;
    code: string;
    ln: string
}
export default function Language() {
    const [selectedCountry, setSelectedCountry] = useState<Country | null | undefined>(null);
    const menu = useRef<Menu>(null);
    const countries: Country[] = [
        { name: 'languages.malayalam', code: 'IN', ln: 'ml' },
        { name: 'languages.hindi', code: 'IN', ln: 'hi' },
        { name: 'languages.english', code: 'US', ln: 'en' },
    ];
    const { t, i18n } = useTranslation();
    const onChangeLanguage = (ln: string) => {
        i18n.changeLanguage(ln)
    }
    const selectedCountryTemplate = (option: Country, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{t(option.name)}</div>
                </div>
            );
        }

        return <span>{t(props.placeholder)}</span>;
    };
    useEffect(() => {
        if (i18n.language)
            setSelectedCountry(countries.find(ls => ls.ln === i18n.language))
    }, [])
    const countryOptionTemplate = (option: Country) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div className="languge-options">{t(option.name)}</div>
            </div>
        );
    };
    const items = [
        {
            label: 'മലയാളം - ML',
            icon: 'pi pi-fw pi-flag',
            command: () => {
                setSelectedCountry({
                    name: 'languages.malayalam',
                    code: 'IN',
                    ln: 'ml'
                });
                onChangeLanguage('ml');
            }
        },
        {
            label: 'हिंदी - HI',
            icon: 'pi pi-fw pi-flag',
            command: () => {
                setSelectedCountry({
                    name: 'languages.hindi',
                    code: 'IN',
                    ln: 'hi'
                });
                onChangeLanguage('hi');
            }
        },
        {
            label: 'English - EN',
            icon: 'pi pi-fw pi-flag',
            command: () => {
                setSelectedCountry({
                    name: 'languages.english',
                    code: 'US',
                    ln: 'en'
                });
                onChangeLanguage('en');
            }
        }
    ];

    return (
        // <div className="flex justify-content-center">
        //     <Dropdown value={selectedCountry} onChange={(e: DropdownChangeEvent) => {setSelectedCountry(e.value);onChangeLanguage(e.value['ln'])}} options={countries} optionLabel="name" placeholder="Select language"
        //         valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="lang-selection-dropdown" style={{border:'none'}} />
        // </div>
        <>
        <Menu model={items} popup ref={menu} />
        {/* <Button  className="mr-2" onClick={(e) => menu.current?.toggle(e)} aria-controls="popup_menu_right" aria-haspopup /> */}
        <Button onClick={(e) => menu.current?.toggle(e)} aria-controls="popup_menu_right" aria-haspopup style={{background:'transparent',color:'#7E7E7E',border:'none'}}>
    {selectedCountry ? (
        <div className="flex align-items-center">
            <img
                alt={selectedCountry.name}
                src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
                className={`mr-2 flag flag-${selectedCountry.code.toLowerCase()}`}
                style={{ width: "18px" }}
            />
            <div>{t(selectedCountry.name)}</div>
            <span className="pi pi-chevron-down ml-1" />
        </div>
    ) : (
        <span>{t("Select language")}</span>
    )}
</Button>
        </>
    )
}
