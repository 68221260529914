import { useContext, useEffect, useState } from "react";
import { profileSvc, dashboardSvc } from "../../../services";
import { AuthContext } from "../../../context";
import { useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { error, log } from "console";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { useTranslation } from 'react-i18next';


const childAppRentUrl = process.env.REACT_APP_CHILD_RENT_APP_URL;
const childAppLoanUrl = process.env.REACT_APP_CHILD_LOAN_APP_URL;
const childAppUserUrl = process.env.REACT_APP_CHILD_USER_APP_URL;
const childAppCustomerUrl = process.env.REACT_APP_CHILD_CUSTOMER_BASE_URL;
const childAppChitUrl = process.env.REACT_APP_CHILD_CHIT_BASE_URL;
const childAppReportUrl = process.env.REACT_APP_CHILD_REPORTS_APP_URL;
const childAppAgentUrl = process.env.REACT_APP_CHILD_AGENT_APP_URL;
const childAppAccessManagementUrl = process.env.REACT_APP_CHILD_ACCESS_MANAGEMENT_APP_URL;
const childAppInventoryUrl = process.env.REACT_APP_CHILD_INVENTORY_APP_URL;
const childAppBaseUrl = process.env.REACT_APP_BASE_URL;
const Home = () => {
  const location = useLocation()
  const navigateChildApp = location.state?.navigateChildApp;
  const { userId } = useContext(AuthContext);
  const [capitalAmount, setCapitalAmount] = useState<any>("");
  const [selectedChildApps, setSelectedChildApps] = useState<any>(null);
  const [landingAppPreference, setLandingAppPreference] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [visibleCapDec,setVisibleCapDec] = useState(false)
  const [value, setValue] = useState<any>();
  const [totalUser,setTotalUsers] = useState(0)
  const [totalCustomers,setTotalCustomers] = useState(0)
  const [totalRevenue,setTotalRevenue] = useState(0)
  const { t, i18n } = useTranslation();
  const [btnAddCp,setBtnAddCp] = useState(false)
  const [btnRdcCp,setBtnRdcCp] = useState(false)


  const getCapitalAmount = () => {
    dashboardSvc.getCapitalAmount()
      .then(res => {
        console.log(res)
        
        setCapitalAmount(res.data.cap_amnt);
      })
      .catch((error) => {
        console.log(error)
      })

  }
  const getDashBoardREports = () => {
    dashboardSvc.getDashBoardReports()
      .then(res => {
        console.log(res)
        const usersCount     = res.data.users.userCount;
        const revenue        = res.data.revenue.totalRev;
        const customersCount = res.data.customers.customerCount
        setTotalUsers(()=>usersCount?usersCount:0)
        setTotalCustomers(()=>customersCount?customersCount:0)
        setTotalRevenue(()=>revenue?revenue:0)
      })
      .catch((error) => {
        console.log(error)
      })

  }
  const reduceCapitalAmount = ()=>{
    if(value>=1)
    console.log(value,"The value")
    dashboardSvc.reducecapitalAmount({amount:value})
      .then(res => {
        console.log(res)
        getCapitalAmount();
        setVisibleCapDec(false)
        setValue("")
        setBtnRdcCp(false)
      })
      .catch((error) => {
        console.log(error)
        setBtnRdcCp(false)
      })

  }
  const addCapitalAmount = () => {
    if(value>=1)
    dashboardSvc.addcapitalAmount({amount:value})
      .then(res => {
        console.log(res)
        getCapitalAmount();
        setVisible(false)
        setValue("")
        setBtnAddCp(false)
      })
      .catch((error) => {
        setBtnAddCp(false)
        console.log(error)
      })

  }

  useEffect(() => {
    // getDashBoardREports();
    getCapitalAmount();
    
    if (userId && navigateChildApp)
      profileSvc.get(userId)
        .then(res => {
          if (res.data.app_preference) {
            // setLandingAppPreference(true)
            // window.location.href = (res.data.app_preference)
          }

        })

  }, []);

  const childApps: any = [
    { name: t("Base App"), url: childAppBaseUrl, icon: "pi pi-home" },
    { name: t('Rent App'), url: childAppRentUrl, icon: 'pi pi-wrench' },
    { name: t('Loan App'), url: childAppLoanUrl, icon: 'pi pi-money-bill' },
    { name: t('User App'), url: childAppUserUrl, icon: 'pi pi-user' },
    { name: t('Agent App'), url: childAppAgentUrl, icon: "pi pi-user" },
    { name: t('Access Management'), url: childAppAccessManagementUrl, icon: "pi pi-cog" },
    { name: t('Customer App'), url: childAppCustomerUrl, icon: "pi pi-users" },
    { name: t("Inventory App"), url: childAppInventoryUrl, icon: "pi pi-shopping-cart" },
    { name: t("Chit Management"), url: childAppChitUrl, icon: "pi pi-wallet" },
    { name: t("Reports"), url: childAppReportUrl, icon: "pi pi-book" },
  ];

  const preferenceApp = (url: any) => {
    setSelectedChildApps(url)
    profileSvc.preferenceApp(userId, url)
  }
  return (
    <>
      {/* {!landingAppPreference &&
      <div>
        <label>Landing App preference</label>
        <div >
          <Dropdown value={selectedChildApps} onChange={(e) => { preferenceApp(e.value) }} options={childApps} optionValue='url' optionLabel="name"
            placeholder="Select a App" className="w-full md:w-14rem" />
        </div>
      </div>
    } */}

      <div className="grid">
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>

                <span className="block text-500 font-medium mb-3">{t("Capital Amount")}</span>


                <div className="text-900 font-medium text-3xl"> {capitalAmount
                  ? new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR'
                  }).format(capitalAmount)
                  : "N/A"
                }</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-dollar text-blue-500 text-xl"></i>
              </div>
            </div>
            <div style={{textAlign:"end"}}>
            
              <i className="pi pi-plus-circle text-blue-500 text-3xl mr-1 " onClick={() => setVisible(true)} style={{ cursor: "pointer" }}></i>

              <i className="pi pi-minus-circle text-blue-500 text-3xl ml-1" onClick={() => setVisibleCapDec(true)} style={{ cursor: "pointer" }}></i>

            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>

                <span className="block text-500 font-medium mb-3">{t("Revenue")}</span>
                <div className="text-900 font-medium text-3xl">{totalRevenue}</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-map-marker text-orange-500 text-xl"></i>
              </div>
            </div>
            <div style={{ textAlign: "end" }} >
              {/* <i className="pi pi-plus-circle text-blue-500 text-3xl "  style={{ cursor: "pointer" }}></i> */}
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-500 font-medium mb-3">{t('Customers')}</span>

                <div className="text-900 font-medium text-3xl">{totalCustomers}</div>

              </div>
              <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-inbox text-cyan-500 text-xl"></i>
              </div>
            </div>
            <div style={{ textAlign: "end" }} >
              {/* <i className="pi pi-plus-circle text-blue-500 text-3xl "  style={{ cursor: "pointer" }}></i> */}
            </div>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-3">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <div className="flex justify-content-between mb-3">
              <div>

                <span className="block text-500 font-medium mb-3">{t('Users')}</span>
                <div className="text-900 font-medium text-3xl">{totalUser}</div>
              </div>
              <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                <i className="pi pi-comment text-purple-500 text-xl"></i>
              </div>
            </div>
            <div style={{ textAlign: "end" }} >
              {/* <i className="pi pi-plus-circle text-blue-500 text-3xl "  style={{ cursor: "pointer" }}></i> */}
            </div>
          </div>
        </div>
      </div>

      {visible &&
        <div className="card flex justify-content-center">
          <Dialog header={t("Add Capital Amount")} visible={visible} style={{ width: '30vw' }} onHide={() => {setVisible(false);setValue("")}} >
          <div className="flex-auto " >
                <label htmlFor="currency-india" className="font-bold block mb-2">{t("Enter the amount")}</label>
                <InputNumber inputId="currency-india" value={value} onValueChange={(e) => (setValue(e.value))} mode="currency" currency="INR" currencyDisplay="code" locale="en-IN" />
            </div>
            <div className="mt-3" style={{ textAlign: "end" }} >
              <Button label={t("Cancel") + ''}  icon="pi pi-times" onClick={() => {setVisible(false);setValue("")}} className="p-button-danger p-button-raised mr-3"  />
              <Button label={t("Save") + ''} icon="pi pi-check" disabled={btnAddCp} onClick={() =>{setBtnAddCp(true); addCapitalAmount()}}  />
            </div>
          </Dialog>
        </div>
      }
      {visibleCapDec &&
        <div className="card flex justify-content-center">
          <Dialog header={t("Reduce Capital Amount")} visible={visibleCapDec} style={{ width: '30vw' }} onHide={() => {setVisibleCapDec(false);setValue("")}} >
          <div className="flex-auto " >
                <label htmlFor="currency-india" className="font-bold block mb-2">{t("Enter the amount")}</label>
                <InputNumber inputId="currency-india" value={value} onValueChange={(e) => (setValue(e.value))} mode="currency" currency="INR" currencyDisplay="code" locale="en-IN" />
            </div>
            <div className="mt-3" style={{ textAlign: "end" }} >
              <Button label={t("Cancel") + ''} icon="pi pi-times" onClick={() => {setVisibleCapDec(false);setValue("")}} className="p-button-danger p-button-raised mr-3" />
              <Button label={t("Save") + ''} icon="pi pi-check" disabled={btnRdcCp} onClick={() =>{ setBtnRdcCp(true);reduceCapitalAmount()}}  />
            </div>
          </Dialog>
        </div>
      }
    </>
  )
}

export default Home;