import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import './DataGrid.css';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { Menu } from 'primereact/menu';

interface IDataGridProps {
    setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
    tittle: string;
    data: any[];
    totalRecords: number;
    reload: boolean;
    setReload(val: boolean): void;
    columns: Array<{
        field: string;
        header: string;
        type?: string;
    }>;
    extraAction: any;
    onViewDeatils(id?: string): void;
    paginationProps(page: string, limit: string): void;
    onCreateOrEdit(edit: boolean, id?: string): void;
    deleteByIdList(selectedIds: any): unknown;
    SearchBar?: any;
    filter?: any;
    addNew?: boolean;
    report?: any[];
    showActions?: boolean;
    paginator?:any;
}

declare module 'jspdf' {
    interface jsPDF {
        autoTable: (options: any) => jsPDF;
    }
}

const DataGridV3 = (props: IDataGridProps) => {
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<any[]>([]);
    const dt = useRef<DataTable<any>>(null);
    const menuRef = useRef<Menu>(null);
    const { t } = useTranslation();

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setLimit(event.rows);
        props.paginationProps(event.page.toString(), event.rows.toString());
    };


    const multipleDelete = () => {
        const selectedIds = selected.map(item => item._id);
        if (selectedIds.length) {
            props.deleteByIdList({ idHistory: selectedIds });
            setSelected([]); // Clear selection after deletion
        }
    };

    const deleteDialogFooter = (
        <>
            <Button label={t("No").toString()} 
            icon="pi pi-times" 
            style={{ backgroundColor: '#B0B0B0', color: 'White' }} // Ash background and black text color
            className="p-button-text" 
            onClick={() => setDeleteDialog(false)} />

            <Button label={t("Yes").toString()} 
            icon="pi pi-check" 
            className="p-button-text" 
            style={{ backgroundColor: '#f44336', color: 'white' }} // Setting red background and white text color
            onClick={() => { multipleDelete(); setDeleteDialog(false); }} />

        </>
    );

    const handleReload = () => {
        setLoading(true);
        setTimeout(() => {
            props.setReload(true);
            setLoading(false);
        }, 1000);
    };

    const exportCSV = () => {
        if (dt.current) dt.current.exportCSV();
    };

    const formatDate = (value: any) => {
        return value.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const dateBodyTemplate = (rowData: any, field: string) => formatDate(new Date(rowData[field]));

    const sourceBodyTemplate = (rowData: any, field: string) => (
        <Badge value={rowData?.source ? rowData.source.toString() : "N/A"} />
    );

    const statusBodyTemplate = (rowData: any) => {
        const status = rowData.status.toString();
        return (
            <Badge
                value={status}
                severity={
                    status === 'approved' ? "success"
                    : status === 'rejected' ? "warning"
                    : status === 'created' ? 'warning'
                    : status === 'closed' ? 'danger'
                    : 'info'
                }
            />
        );
    };

    const paginatorTemplate: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const rowsPerPageOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];
            return (
                <>
                    <span className="mx-1 pagination-no">
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={rowsPerPageOptions} onChange={options.onChange} />
                </>
            );
        },
        CurrentPageReport: (options) => (
            <span className="pagination-no">
                {options.first} - {options.last} of {options.totalRecords}
            </span>
        )
    };

    const formatIndianRupee = (rowData: any, field: string) => {
        const formatter = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2
        });
        return rowData[field] !== undefined ? formatter.format(rowData[field]) : "";
    };

    const exportPDF = () => {
        const doc = new jsPDF();
        if (!props.columns || !props.data) return;
        
        const columns = props.columns.map(col => ({ title: t(col.header), dataKey: col.field }));
        const data = props.data.map(row => {
            const obj: any = {};
            props.columns.forEach(col => obj[col.field] = row[col.field]);
            return obj;
        });

        doc.autoTable({
            columns,
            body: data,
            margin: { top: 20 },
            styles: { fontSize: 10 },
            headStyles: { fillColor: [0, 102, 204], textColor: [255, 255, 255] }
        });

        doc.save(`${props.tittle}.pdf`);
    };

    const menuItems = [
        { label: 'Export to Excel', icon: 'pi pi-file-excel', command: exportCSV },
        { label: 'Export as PDF', icon: 'pi pi-file-pdf', command: exportPDF }
    ];

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (menuRef.current) menuRef.current.toggle(event);
    };

    const viewDetails = async(id: string) => {
       await props.onViewDeatils(id);
    };

    const editRecord = (id: string) => {
        props.onCreateOrEdit(true, id);
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div>{t(props.tittle)}</div>
                <div className="common-header-toolbar">
                    <div className="flex">
                        {props.SearchBar && <props.SearchBar />}
                        {/* {selected.length > 1 && (
                            <Button
                                label={t("delet") as string}
                                icon="pi pi-trash"
                                className="p-button-danger p-button-raised p-button-sm ml-3"
                                onClick={() => setDeleteDialog(true)}
                                style={{ backgroundColor: '#11B3CF', border: 'none' }}
                            />
                        )} */}
                        <div>{props.filter && <props.filter />}</div>
                    </div>
                    <div className="flex">
                        {selected.length > 0 && (
                            <div className='flex'>
                            {props.SearchBar && <props.SearchBar />}
                            {selected.length > 0 && (
                                <div className="action-buttons">
                                    <Button 
                                        // label={t("View ") || ""} 
                                        tooltip={t("View") || "View"} 
                                        icon="pi pi-eye" 
                                        className="p-button-info  p-button-rounded" 
                                        onClick={() => viewDetails(selected[0]._id)} 
                                        style={{ backgroundColor: '#11B3CF', border: 'none', marginRight: '8px' }} 
                                        disabled={selected.length !== 1} 
                                    />
                                    <Button 
                                        // label={t("Edit") || ""}
                                        tooltip={t("Edit") || "Edit"} 
                                        icon="pi pi-pencil" 
                                        className="p-button-info p-button-rounded" 
                                        onClick={() => editRecord(selected[0]._id)} 
                                        style={{ backgroundColor: '#11B3CF', border: 'none', marginRight: '8px' }} 
                                        disabled={selected.length !== 1} 
                                    />
                                    <Button 
                                        // label={t("Delete") || ""} 
                                        tooltip={t("Delete") || "Delete"}
                                        icon="pi pi-trash" 
                                        className="p-button-info p-button-rounded mr-2" 
                                        onClick={() => setDeleteDialog(true)} 
                                        style={{ backgroundColor: '#11B3CF', border: 'none' }} 
                                    />
                                </div>
                            )}
                
                        </div>
                        )}
                        <Button
                            tooltip={t("Reload") || "Reload"}
                            style={{ backgroundColor: '#11B3CF', width: '44px', height: '43px' }}
                            tooltipOptions={{ position: "mouse" }}
                            icon={`pi pi-refresh ${loading ? 'pi-spin' : ''}`}
                            className="p-button-success p-button-rounded p-button-raised p-button-secondary mr-2"
                            onClick={handleReload}
                            // onClick={()=>setLoading(true)}
                            disabled={loading}
                        />

                    {props.data && props.data.length > 0 && (
                        <>
                            <Menu model={menuItems} popup ref={menuRef} />
                            <Button
                                icon="pi pi-ellipsis-v"
                                tooltip={t('Export') || 'Export'}
                                className="p-button-rounded"
                                style={{ backgroundColor: '#11B3CF'}}
                                onClick={handleMenuClick} // Toggle menu
                            />
                        </>
                    )}
                    {props.addNew && (
                        <Button
                            style={{ backgroundColor: '#11B3CF' }}
                            className="p-button-success p-button-rounded p-button-raised p-button-sm mr-2 ml-2"
                            tooltipOptions={{ position: "mouse" }}
                            // label={t("Add New") + ' ' + props.tittle}
                            tooltip={t('Add') || 'Add'}
                            icon="pi pi-plus"
                            onClick={() => { props.onCreateOrEdit(true); }}
                        />
                    )}
                    </div>
                    
                </div>
                <DataTable
                    ref={dt}
                    value={props.data}
                    selection={selected}
                    sortField="createdAt"
                    sortOrder={-1}
                    onSelectionChange={(e: any) => setSelected(e.value)}
                    dataKey="_id"
                    className="datatable-responsive custom-datatable"
                    emptyMessage={t("No data found")}
                    scrollable
                    scrollHeight="400px"
                    style={{ opacity: loading ? 0.5 : 1 }}
                    selectionMode="multiple"
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    {props.columns.map((col, i) => (
                        col.type === '' ? (
                            <Column key={i} field={col.field} header={t(col.header) as string} body={(data) => dateBodyTemplate(data, col.field)} />
                        ) : col.type === '' ? (
                            <Column key={i} field={col.field} header={t(col.header) as string} body={(data) => formatIndianRupee(data, col.field)} />
                        ) : col.type === 'status' ? (
                            <Column key={i} field={col.field} header={t(col.header) as string} body={statusBodyTemplate} />
                        ) : col.type === '' ? (
                            <Column key={i} field={col.field} header={t(col.header) as string} body={(data) => sourceBodyTemplate(data, col.field)} />
                        ) : (
                            <Column key={i} field={col.field} header={t(col.header) as string} />
                        )
                    ))}
                </DataTable>
                {/* <Paginator
                    first={first}
                    rows={limit}
                    totalRecords={props.totalRecords}
                    rowsPerPageOptions={[5, 10, 20, 120]}
                    onPageChange={onPageChange}
                    template={paginatorTemplate}
                /> */}
            </div>
            <Dialog
                visible={deleteDialog}
                style={{ width: '450px' }}
                header={<span style={{ color: 'red',fontWeight: 'bold',textAlign: 'center', }}>{t("Confirmation")}</span>}
                modal
                footer={deleteDialogFooter}
                onHide={() => setDeleteDialog(false)}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem', color: 'red' }} />
                    <span>{t("Are you sure you want to delete the selected items?")}</span>
                </div>
            </Dialog>
        </div>
    );
};

export default DataGridV3;
