import * as Yup from 'yup';

export const ratesSave = Yup.object().shape({
  vehicleMake: Yup.string()
    .matches(/^[A-Za-z\s]+$/, 'Only contain letters and spaces')
    .required('Required')
    .test(
      'vehicleId-check',
      'Invalid vehicle name',
      function (value) {
        const { vehicleId } = this.parent;
        return value && vehicleId && vehicleId.trim() !== '';
      }
    ),

  vehicleId: Yup.string()
  .matches(/^[A-Za-z0-9]+$/, 'Characters and numbers are allowed')
  .required('Required'),

  registration_number: Yup.string()
  .required('Required'),
  
  baseRate: Yup.number()
    .typeError('Base Rate must be a number')
    .nullable()  // Ensures that the value can be null but is still required
    .positive('Required') // Optional: Ensure it’s a positive number
    .required('*Base Rate is required'),

  kmRate: Yup.number()
    .typeError('KM Rate must be a number')
    .nullable()  // Ensures that the value can be null but is still required
    .positive('Required') // Optional: Ensure it’s a positive number
    .required('*KM Rate is required'),

  maxKmPerDay: Yup.number()
    .typeError('Max KM Per Day must be a number')
    .nullable()  // Ensures that the value can be null but is still required
    .positive('Required') // Optional: Ensure it’s a positive number
    .required('*Max KM Per Day is required'),

  // comments: Yup.string()
  //   .max(500, 'Comments cannot exceed 500 characters')
  //   .required('Comment is required'),
});
