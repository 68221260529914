import React from 'react';
import './App.css';
import './assets/fonts/fonts.css'
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import theme from './styles/theme';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import { AuthProvider } from './context';

import Router from './routes';
function App() {
  return (
    <BrowserRouter  >
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          theme='colored'
          closeOnClick
        />
        <AuthProvider>
        <Router />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>

  );
}

export default App;
