import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "primereact/button";
import { t } from "i18next";
import DataGridV3 from "../../../../components/DataGridV3/DataGridV3";
import AddModal from "./addmodal";
import { toast } from "react-toastify";
import VehicleViewModal from "./ViewDetailsModal";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { list, get, Delete, multipleDelete, update } from "../../../../services/rentAdminPanelService/AddVehicleService";
import { Dialog } from "primereact/dialog";
import AddVehicleModal from "./addmodal";
import { AutoComplete } from "primereact/autocomplete";
import { useTranslation } from "react-i18next";
import { Paginator } from "primereact/paginator";

const Vehicle: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotal] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [selectSource, setSelectSource] = useState<any>("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [visibleView, setVisibleView] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [vehicleDetails, setVehicleDetails] = useState<any>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<any>("");
  const [reload , setReload] = useState<boolean>(true)
  const [selectedMake,setSelectedMake] = useState<any>(null);
  const [selectSeatCap,setSelectSeatCap] = useState<any>(null);
  const [selectedPollution,setSelectedPollution] = useState<any>(null);
  const [selectedInsurance,setSelectedInsurance] = useState<any>(null);
  const [values, setValues] = useState<any>("");
  const [driverSearch, setDriverSearch] = useState<string>("");
  const [filteredDrivers, setFilteredDrivers] = useState<any[]>([]);
  const [driverDetails, setDriverDetails] = useState<any>(null);
  const { t,i18n } = useTranslation();

  const handleReload =() =>{
    setSelectedPollution(null)
    setSelectedInsurance(null)
    setSelectSeatCap(null)
    setPage(1)
    setTableData([])
    setReload(true)
    getAllData(page, limit)
  };
  const getAllData = async(page:number,limit:number) => {

    const filter = {
      pollution_expiry:selectedPollution,
      insurance_expiry:selectedInsurance,
      seat_capacity:selectSeatCap
    }
    await list( (page).toString(),limit.toString(),filter).then((res: any) => {
      const formattedData = res.data.res.map((item: any, index: number) => ({
        ...item,
        key: item.id || index,
        make:item.make,
        pollution_expiry: formatDateToDDMMYYYY(item.pollution_expiry),
        insurance_expiry: formatDateToDDMMYYYY(item.insurance_expiry),
        seat_capacity: item.seat_capacity,
      }));
      setTableData(formattedData);
      setTotal(res.data.totalPages * limit);
      setReload(false)
    });
  };

  useEffect(() => {
    if (reload) {
      getAllData(page, limit)
      setReload(false)
      setId("")
    }
}, [reload, page, limit, values])

  const details = async (id: string) => {
    try {
      const response = await get(id);
      if (response && response.data && response.data.data) {
        const vehicleData = response.data.data;
        const formattedDriverData = {
          ...vehicleData,
          pollution_expiry: formatDateToDDMMYYYY(vehicleData.pollution_expiry),
          insurance_expiry: formatDateToDDMMYYYY(vehicleData.insurance_expiry),
        };
        setValues(formattedDriverData);
        setViewDetails(true);
      } else {
        console.log("No data found for this ID");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const deleteByIdList = (i: any) => {
    multipleDelete(i).then((res) => {
      if (res) {
        toast.success(t("Records deleted successfully"));
        getAllData(page, limit);
      } else {
        toast.error(t("Failed to delete records"));
      }
    });
  };

  const onPageChange = (event: any) => {
    const newPage = event.page + 1; 
    const rowsPerPage = event.rows;
    setPage(newPage); 
    setLimit(rowsPerPage); 
    getAllData(newPage, rowsPerPage);
  };

  const onCreateOrUpdate = (t: boolean, i: string) => {
    console.log("ti",t,i);
    
    if (t && i) {
      get(i).then((res) => {
        const data = res.data.data;
        setValues({
          ...data,
          date: data.date ? new Date(data.date) : null,
        });
      });
    }
    setId(i);
    setVisible(t);
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage(parseInt(p, 10));
    setLimit(parseInt(l, 10));
    getAllData(page, limit);
  };

  function formatDateToDDMMYYYY(isoDate: string): string {
    let date = new Date(isoDate);
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    getAllData(page, limit);
  }, [page, limit,selectSeatCap,selectedInsurance,selectedMake,selectedPollution]);

  useEffect(() => {
    if (values) {
      const vehicleDetails = {
        make: values.make || "N/A",
        model: values.model || "N/A",
        vehicleID: values.vehicle_id || "N/A",
        type: values.type || "N/A",
        // plate: values.plate || "N/A",
        price: values.price || "N/A",
        colour:values.colour || "N/A",
        Registration_number: values.registration_number || "N/A",
        Pollution_expiry: values.pollution_expiry || "N/A",
        insurance_expiry: values.insurance_expiry || "N/A",
        seat_capacity: values.seat_capacity || "N/A",
      };
      setVehicleDetails(vehicleDetails);
    }
  }, [values]);

  const handleView = (vehicleId: string) => {
    setSelectedVehicleId(vehicleId);
    setVisibleView(true);
  };
  const searchDrivers = async (searchTerm: string) => {
    try {
        const res = await list((page + 1).toString(),limit.toString());
        const drivers = res.data.res || [];
        console.log(drivers);

        const filtered = drivers.filter((driver: any) =>
            driver.make.toLowerCase().includes(searchTerm.toLowerCase())
        
        
        );
        console.log(".......................",filtered);
        setFilteredDrivers(filtered);
    } catch (error) {
        console.error("Error fetching drivers:", error);
    }
};

  const columns = [
    { field: "vehicle_id", header: t("Vehicle ID")},
    { field: "make", header: t("Make") },
    { field: "model", header: t("Model") },
    { field: "type", header: t("Type") },
    // { field: "plate", header: "Plate No" },
    { field: "registration_number", header: t("Registration No") },
    { field: "pollution_expiry", header: t("Pollution Expiry") },
    { field: "insurance_expiry", header: t("Insurance Expiry") },
    { field: "seat_capacity", header: t("Seat Capacity") },
  ];

  // const sourceOptions = [
  //   { name: t("SUV"), code: "SUV" },
  //   { name: t("SEDAN"), code: "SEDAN" },
  // ];

  const makeOptions= [
    { name: 'Toyota', value: 'toyota' },
    { name: 'Option 2', value: 'Option2' },
    { name: 'Option 3', value: 'Option3' },
    { name: 'Option 4', value: 'Option4' },
    { name: 'Option 5', value: 'Option5' },

  ];
  const seatOption = 
    [
      {label:"2",value:2},
      {label:"3",value:3},
      {label:"4",value:4},
      {label:"5",value:5},
      {label:"6",value:6},
      {label:"7",value:7},
      {label:"8",value:8},
      {label:"9",value:9},
      {label:"10",value:10}

    ]
  const deleteDialogFooter = (
    <>
      <Button
        label={t("No") ||"No"}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label={t("Yes") ||"Yes"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteByIdList(id);
          setDeleteDialog(false);
        }}
      />
    </>
  );

  const VehicleFilter = () => {
    return(
    <div>
      <Button
        // label={t("Filter") as string}
        icon="pi pi-filter"
        tooltip={t("Filter") || "Filter"}
        onClick={() => setShowFilters(!showFilters)}
        className="mr-3 p-button-rounded"
        style={{ backgroundColor: "#11B3CF" }}
      />
      {showFilters && (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "10px" }}>
          
          {/* Make Filter */}
          {/* <AutoComplete
              id="make"
              value={driverSearch}
              suggestions={filteredDrivers} // Use the whole object for suggestions
              completeMethod={(e) => searchDrivers(e.query)} // Trigger search when user types
              field="make" // The field to display in the dropdown
              dropdown
              onChange={(e) => setDriverSearch(e.value)} // Update search term on change
              onSelect={(e) => {
                const selectedDriver = e.value; // Select the full driver object
                setDriverSearch(selectedDriver.make); // Update search with first name
                setDriverDetails(selectedDriver); // Update the driver details with the selected driver
              }}
              placeholder="Search for the driver's first name..."
            /> */}

           {/* Pollution Expiry Filter */}
          <Calendar
            value={selectedPollution}
            onChange={(e) => setSelectedPollution(e.value)}
            placeholder={t("Pollution Expiry") as string}
            dateFormat="dd-mm-yy"
            showIcon
            className="mr-2"
          />
           {/* Insurance Expiry Filter */}
          <Calendar
            value={selectedInsurance}
            onChange={(e) => setSelectedInsurance(e.value)}
            placeholder={t("Insurance Expiry") as string}
            dateFormat="dd-mm-yy"
            showIcon
            className="mr-2"
          />
           {/* Seat Capacity Filter */}
          <Dropdown
            value={selectSeatCap}
            onChange={(e) => setSelectSeatCap(e.value)}
            options={seatOption} // Example seat capacities
            placeholder={t("Seat Capacity") as string}
            className="mr-2"
          />
        </div>
      )}
    </div>
  );
  }
  return (
    <>
      <div className="ml-auto flex justify-between gap-10">
          <h1>{t("Vehicles")}</h1>
      </div>

      <DataGridV3
        tittle={t("")}
        data={tableData}
        columns={columns}
        paginator
        totalRecords={totalRecords}
        paginationProps={onPageChange}
        onViewDeatils={details}
        reload={false}
        filter={VehicleFilter}
        setReload={handleReload}
        deleteByIdList={deleteByIdList}
        extraAction={() => {}}
        addNew={true}
        showActions={selectedRows.length > 0}
        setSelectedRows={setSelectedRows}
        onCreateOrEdit={onCreateOrUpdate}
      />
      <Paginator
        first={(page - 1) * limit} 
        rows={limit}
        totalRecords={totalRecords}
        rowsPerPageOptions={[2,5, 10, 20]} 
        onPageChange={onPageChange} 
      />
      <AddModal
        setVisible={setVisible}
        visible={visible}
        updateTableData={() => setReload(true)}
        id={id}
        setReload={setReload}

      />

      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={t("Confirm")}
        modal
        onHide={() => setDeleteDialog(false)}
        footer={deleteDialogFooter}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {!id ? (
            <span>{t("Are you sure you want to delete the selected items?")}</span>
          ) : (
            <span>{t("Are you sure you want to delete?")}</span>
          )}
        </div>
      </Dialog>

      {viewDetails && (
        <VehicleViewModal
          setVisible={setViewDetails}
          visible={viewDetails}
          details={vehicleDetails || {}}
          title={t("Vehicle Details")}
        />
      )}
    </>
  );
};

export default Vehicle;

//

