import { request } from 'http';
import { axiosInstance } from '../interceptors';
import IEcommSupplier from '../../interfaces/models/IEcommSupplier';

export const EcommSupplierSave = async (req: any) => {
    return await axiosInstance.post(`inventory/ecommSupplier`, req);
};

export const EcommSupplierList = async ( page: string,limit: string,filter:any={}) => {
    return await axiosInstance.get(`inventory/ecommSupplier/list`, {
        params: {
          page,
          limit,
          ...filter,
        }
      });
    };

export const EcommSupplierUpdate = async ( request: IEcommSupplier) => {
    return await axiosInstance.put(`inventory/ecommSupplier/update`,request);
};




export const EcommSupplierDelete = async (id:string) => {
    return await axiosInstance.put(`inventory/ecommSupplier/delete/${id}`);
};

// export const getByIdVehicle = async (id:string) => {
//     return await axiosInstance.get(`inventory/vehicle/${id}`);
// };

export const EcommSupplierGet= async (id:string) => {
    return await axiosInstance.get(`/inventory/ecommSupplier/getById/${id}`);}


    export const EcommSupplierMultipleDelete = async (idHistory: string[] | number[]) => {
        console.log('IDs to delete:', idHistory); // Log the idHistory before sending the request
       
        try {
          const response = await axiosInstance.post(`/inventory/ecommSupplier/multipleDelete`, idHistory);
          console.log("??????????????????????",idHistory);
          
          return response;
        } catch (error) {
          console.error('Error deleting multiple records:', error);
          throw error; // Re-throw the error to handle it elsewhere if needed
        }
      };