import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Formik, Form, FormikHelpers } from 'formik';
import { changePassword } from '../../../services';
import { newPasswordSave } from "../../../validation";
import ErrorMessage from '../../../components/common/ErrorMessage';
import { toast } from 'react-toastify';
import { getCookie } from '../../../utils/cookieUtils';

const ChangePassword = () => {
    const initial = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    }
  const [initialValues, setInitialValues] = useState(initial)
  const [userId,setUserId] =useState('')


 
    

  useEffect(() => {
    const user = getCookie("user")
    if (user) {
      const data=user
      setUserId(data.user.id)
    }
  }, []);


  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    console.log(userId)
const request={
  current_password:values.currentPassword,
  new_password:values.newPassword,
  confirm_password:values.confirmPassword,
}
if(userId){
  await changePassword(userId, request);
  toast.success("Password has been changed successfully.");
  setInitialValues(initial)
  formikHelpers.resetForm();
}

};

  return (
    <Formik initialValues={initialValues} validationSchema={newPasswordSave} onSubmit={onSubmit}>
      {
        ({ values, isSubmitting, errors, getFieldProps, touched, isValid,setFieldValue }) => (<Form style={{ width: '400px' }}>
      <div className="p-fluid" >
        <div className="p-field mt-5">
          <label htmlFor="currentPassword">Current Password</label>
          <Password id="currentPassword" value={values.currentPassword} onChange={(e) => setFieldValue('currentPassword',e.target.value)} toggleMask  />
        </div>
        {touched.currentPassword && errors.currentPassword && <ErrorMessage message={errors.currentPassword} id="currentPassword" />}

        <div className="p-field mt-5">
          <label htmlFor="newPassword">New Password</label>
          <Password id="newPassword" value={values.newPassword} onChange={(e) => setFieldValue('newPassword',e.target.value)} toggleMask />
        </div>
        {touched.newPassword && errors.newPassword && <ErrorMessage message={errors.newPassword} id="newPassword" />}

        <div className="p-field mt-5">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <Password id="confirmPassword" value={values.confirmPassword} onChange={(e) => setFieldValue('confirmPassword',e.target.value)} toggleMask />
        </div>
        {touched.confirmPassword && errors.confirmPassword && <ErrorMessage message={errors.confirmPassword} id="confirmPassword" />}
        <div className="p-field mt-5">
          <Button label={isSubmitting ? '' : 'Reset Password'} className="p-button-success w-full p-2 text-md" style={{backgroundColor:'#11B3CF'}} type='submit' loading={isSubmitting} />
        </div>
      </div>
    </Form>)
      }
    </Formik>
  );
};

export default ChangePassword;
