import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { t } from "i18next";
import { toast } from "react-toastify";
import { Formik, Form, FormikHelpers } from "formik";
import IEcommRate from "../../../../../interfaces/models/IEcommRate";
import "./ecommerceRateModal.css";
import {
  EcommRateSave,
  EcommRateget,
  EcommRateupdate,
} from "../../../../../services/ecommAdminPanelService/EcommRateService";
import DataGridV3 from "../../../../../components/DataGridV3/DataGridV3";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ecommRateValidation } from "../../../../../validation/adminPanelValidation/EcommRates";
import ErrorMessage from "../../../../../components/common/ErrorMessage";
import { useTranslation } from "react-i18next";


interface IEcommRateModel {
  visible: boolean;
  setVisible(val: boolean): void;
  id: string | undefined;
  updateTableData: () => void;
  setReload(val: boolean): void;
}

const EcommerceRateModal = ({
  setVisible,
  visible = false,
  id,
  updateTableData,
  setReload,
}: IEcommRateModel) => {
  const initialValues: IEcommRate = {
    date: new Date(),
    // item:"",
    type: "",
    rate: 0,
    unit: "",
    comments: "",
  };

  const [formValues, setFormValues] = useState<IEcommRate>(initialValues);
  const [items, setItems] = useState<IEcommRate[]>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const rateOptions = ['Kg', 'L', 'g', 'ml'];
  const { t,i18n } =useTranslation();

  const clearAll = () => {
    setFormValues(initialValues);
    setItems([]);
  };

  useEffect(() => {
    if (id) {
      EcommRateget(id)
        .then((res) => {
          if (res.data && res.data.data) {
            setFormValues(res.data.data);
            setItems(res.data.data.items || []);
          }
        })
        .catch(() => {
          toast.error(t("Failed to load data."));
        });
    } else if (!id && visible) {
      clearAll();
    }
  }, [id, visible]);

  const formatDateToYYYYMMDD = (date: Date): string => {
    if (!date) return "";
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}/${month}/${day}`;
  };

  const handleEditItem = (item: IEcommRate, index: number) => {
    // Open the modal and set the form values to the item to be edited
    setFormValues(item);
    setEditingIndex(index);
    setVisible(true); // Assuming setVisible controls the modal visibility
    };

  const handleAddItem = () => {
    if (formValues.rate > 0 && formValues.type) {
      if (editingIndex !== null) {
        // If we're editing an existing item, update it
        setItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[editingIndex] = { ...formValues }; // Update the item at the editing index
          return updatedItems;
        });
        setEditingIndex(null); // Reset the editing index
      } else {
        // If we're adding a new item, append it to the list
        setItems((prevItems) => [...prevItems, { ...formValues }]);
      }
      setFormValues(initialValues); // Reset form values
    } else {
      toast.error(t("Please fill all the fields before adding an item."));
    }
  };
  
  
  const handleDeleteItem = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      // Check if the table data (items) is empty
      if (items.length === 0) {
        toast.error(t("Please add items"));
        return; 
      }
   
      const payload = { ...formValues, items };
      const res = id ? await EcommRateupdate(payload) : await EcommRateSave(payload);
      if (res) {
        toast.success(t("Data saved successfully!"));
        setVisible(false);
        // formikHelpers.resetForm();
        updateTableData();
        setReload(true);
        clearAll();
      }
    } catch (error) {
      toast.error(t("Failed to save data."));
      console.error(error);
    }
  };

  const columns = [
    {
      field: "date",
      header: t("Date"),
      body: (rowData: IEcommRate) =>
        rowData.date ? new Date(rowData.date).toLocaleDateString() : "",
    },
    { field: "type", header: t("Item") },
    // { field: "type", header: "Item Type" },
    { field: "rate", header: t("Item Rate") },
    { field: "unit", header: t("Units") },

  ];

  return (
   <div className="add-modal-container">
    <Dialog
      header={<span style={{ fontWeight: 'bold' }}>{id ? t("Edit Rate") : t("Add Rate")}</span>}
      visible={visible}
      headerClassName="custom-header"
      headerStyle={{ textAlign: "center", color: "black", fontWeight: "bold" }}
      style={{ height: "700px", width: "800px" }}
      onHide={() => setVisible(false)}
    >
        <Formik
          enableReinitialize
          initialValues={formValues}
          // validationSchema={ecommRateValidation}
          onSubmit={handleSubmit}
          key={id || 'new'}
        >
          {({ handleChange, values,errors,touched }) => (
            <Form>
              <div className="grid grid-cols-2 gap-4 mt-2 ml-2">
                <div className="ml-6">
                <label className="block text-gray-700 font-medium">{t("Date")}</label>
                <Calendar
                  id="date"
                  value={values.date ? new Date(values.date) : null}  // Ensure it's a valid Date object
                  onChange={(e) =>
                    setFormValues({
                      ...values,
                      date: e.value instanceof Date ? e.value : new Date(),  // Safely update the date
                    })
                  }
                  dateFormat="dd/mm/yy"  // Correct date format
                  showIcon
                  style={{ height: "40px", width: "300px" }}
                />

                </div>
                <div>
                  <label className="block text-gray-700 font-medium">{t("Item")}</label>
                  <InputText
                    name="type"
                    id="type"
                    value={values.type}
                    onChange={(e) => setFormValues({...values,type: e.target.value }) }
                    placeholder={t("Enter the Item") || "Enter the Item"}
                    style={{height:"40px", width:"300px"}}

                  />
                  <div className="ml-2">
                  {errors.type && touched.type && (
                   <ErrorMessage message={errors.type} id="type" />
                  )}                   
                  </div>
                </div>
                
                <div className="ml-6">
                  <label className="block text-gray-700 font-medium">{t("Item Rate")}</label>
                  <InputText
                    name="rate"
                    id="rate"
                    value={String(values.rate)}
                    onChange={(e) => setFormValues({ ...values, rate: e.target.value ? parseFloat(e.target.value) : 0 })}
                    placeholder={t("Add Item Rate") || "Add Item Rate"}
                    style={{height:"40px", width:"300px"}}

                  />
                  <div className="ml-2">
                  {errors.rate && touched.rate && (
                   <ErrorMessage message={errors.rate} id="rate" />
                  )}                    
                  </div>
                </div>
                <div className="ml-1">
                  <label className="block text-gray-700 font-medium">{t("Unit")}</label>
                  <Dropdown
                    name="unit"
                    id="unit"
                    options={rateOptions}
                    value={values.unit}
                    onChange={(e) =>
                      setFormValues({ ...values, unit: e.value })
                    }
                    placeholder={t("Select Unit") || "Select Unit"}
                    style={{height:"40px", width:"300px"}}

                  />
                   <div className="ml-2">
                   {errors.unit && touched.unit && (
                   <ErrorMessage message={errors.unit} id="unit" />
                  )}                    
                  </div>
                </div>
                <div className="ml-6">
                  <label className="block text-gray-700 font-medium">{t("Comments")}</label>
                  <InputText
                    name="comments"
                    value={values.comments}
                    onChange={(e) => setFormValues({...values, comments: e.target.value})}
                    placeholder={t("Comments") || "Comments"}
                    style={{height:"40px", width:"630px"}}
                  />
                </div>
              </div>
              <div className="flex justify-content-center mt-2">
              <Button
                type="button"
                onClick={handleAddItem}
                tooltip={t("Item") || "Item"}
                label={t("Item") || "Item" }
                icon="pi pi-plus"
                className="mt-3 mr-2"
                style={{ width: "100px",backgroundColor:"#11B3CF" }}
              />
              </div>
              <div className="mt-3">
              <DataTable
                value={items} // The data array you want to display
                className="datatable-responsive"
                paginator
                rows={10} // Adjust the number of rows per page as needed
                rowsPerPageOptions={[5, 10, 25]} // Provide options for rows per page
                // emptyMessage="No data found."
                // headerStyle={{ backgroundColor: '#11B3CF', color: 'white', fontWeight: 'bold' }}
              >
                <Column
                  header={t("Date")}
                  body={(rowData: IEcommRate) => rowData.date ? new Date(rowData.date).toLocaleDateString() : ""}
                  style={{ flexGrow: 1, flexBasis: '200px' }}
                />
                <Column
                  header={t("Item")}
                  field="type"
                  style={{ flexGrow: 1, flexBasis: '200px' }}
                />
                <Column
                  header={t("Item Rate")}
                  field="rate"
                  style={{ flexGrow: 1, flexBasis: '200px' }}
                />
                <Column
                  header={t("Units")}
                  field="unit"
                  style={{ flexGrow: 1, flexBasis: '200px' }}
                />
                <Column
                  header={t("Actions")}
                  body={(rowData: IEcommRate, { rowIndex }) => (
                    <div className="flex justify-content-center mr-3 gap-2">
                    
                    <Button
                      icon="pi pi-trash"
                      type="button"
                      tooltip={t("Delete") || "Delete"}
                      className="p-button-rounded mr-5"
                      onClick={() => handleDeleteItem(rowIndex)}
                    />
                    </div>
                  )}
                  style={{ textAlign: 'center', flexGrow: 1, flexBasis: '100px' }}
                />
              </DataTable>
              </div>
              <div className="flex justify-content-center mt-4 mb-5">
                <Button 
                  type="submit" 
                  // onClick={handleSubmit} 
                  label={id ? t("Update") || "Update": t("Save") || "Save"} 
                  icon="pi pi-check" 
                  className="p-button-success mr-2" 
                  style={{ width: "120px" }} 
                />
                <Button 
                  type="button" 
                  onClick={() => setVisible(false)} 
                  label={t("Cancel") || "Cancel"} icon="pi pi-times" 
                  className="p-button-secondary mr-2" 
                  style={{ background:"11B3CF",width: "120px" }}/>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EcommerceRateModal;