import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import {useLocation} from 'react-router-dom';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { otpValidation } from '../../../validation';
import { Formik, Form, FormikHelpers } from 'formik';
import { otpVerification } from '../../../services';
import { useNavigate,Link } from 'react-router-dom';
import { StyledH3 } from '../../../components/styledcomponents';

function CheckMail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues = { otp: ''};
  const location = useLocation()
  const userId= location.state?.userIdentity;

  const onSubmit = async (e: any,actions: FormikHelpers<{
    otp: string;
  }>) => {
    const res = await otpVerification(e,userId);
    if (res)
      navigate('/setnewpassword',{state:{userId}});
  };


  return (
    <Formik initialValues={initialValues} validationSchema={otpValidation} onSubmit={onSubmit}>
      {
        ({ isSubmitting, errors, getFieldProps, touched, isValid }) => (<Form>
          <div className='surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden' >
            <div className="flex flex-column align-items-center justify-content-center">
              <div style={{ borderRadius: '56px', padding: '0.3rem', }}>
                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                  
                  <StyledH3 className="fmt-forgot-password-title">OTP Sent Successfully</StyledH3>
                  <hr></hr>
                  <div>
                  <p className="oxd-text oxd-text--p" ><i className="pi pi-envelope mr-2"></i>An OTP has been sent you via email</p>
                  </div>
                  <div>
                    <div className="flex flex-column gap-2 mb-3">
                    <label htmlFor="email" className="auth-label ">
                        Enter the OTP
                      </label>
                      <InputText id="email1" aria-describedby='username-help' type="text" className={`w-full md:w-30rem ${touched.otp && errors.otp ? 'p-invalid' : ''}`} style={{ padding: '1rem' }} {...getFieldProps(('otp'))} name='otp' />
                      {touched.otp && errors.otp && <ErrorMessage message={errors.otp} id="username-help" />}
                    </div>
                    <hr></hr>
                    
                    <Button label={isSubmitting ? '' : 'Verify'} className="w-full p-2 text-md" type='submit' loading={isSubmitting} />
                    <div className="flex align-items-center justify-content-between mb-5 gap-5">
                    <Link to="/login" className="font-medium no-underline ml-auto text-right cursor-pointer mt-3" style={{ color: 'var(--primary-color)' }}>Back to Login ?</Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>)
      }
    </Formik>

  );
}

export default CheckMail;
