import * as Yup from 'yup';
import { subYears } from 'date-fns';

export const driverSave = Yup.object().shape({
    date: Yup.date()
        .required('Required'),
    first_name: Yup.string()
        .matches(/^[A-Za-z]+$/, 'Only contain characters')
        .required('Required'),
    last_name: Yup.string()
        .matches(/^[A-Za-z]+$/, 'Only contain characters')
        .required('Required'),
    gender: Yup.string()
        .oneOf(['male', 'female', 'other'], 'Invalid gender')
        .required('Required'),
    phone_number: Yup.string()
        .matches(
        /^\+?\d{10,12}$/,  // Match an optional '+' followed by exactly 10 digits
        'Phone number must be exactly 10 digits and may optionally start with a "+" followed by the country code'
        )
        .required('Phone number is Required'),
    address: Yup.string()
        .required('Required'),
        date_of_birth: Yup.date()
        .required('Required')
        .test(
          'is-18-or-older',
          'Driver must be at least 18 years old',
          function (value) {
            if (!value) return false; // Ensure a value is present
            const today = new Date();
            const eighteenYearsAgo = subYears(today, 18);
            return value <= eighteenYearsAgo; // Check if DOB is 18 years or older
          }
        ),
    license_no: Yup.string()
        .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')
        .required('Required'),
    license_expiry: Yup.date()
        .required('Required'),
});
