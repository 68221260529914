import React, { useState , useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import { Formik, Form, FormikHelpers } from "formik";
import IEcommSupplier from "../../../../../interfaces/models/IEcommSupplier";
import "./ecommerceSupplierModal.css";
import { EcommSupplierSave , EcommSupplierGet , EcommSupplierUpdate } from "../../../../../services/ecommAdminPanelService/ecommSupplierService";
import ErrorMessage from "../../../../../components/common/ErrorMessage";
import { supplierValidation } from "../../../../../validation/adminPanelValidation/supplier";
import { useTranslation } from "react-i18next";

interface IEcommSupplierModel {
  visible: boolean;
  setVisible(val: boolean): void;
  id: string | undefined;
  updateTableData: () => void;
  setReload(val: boolean): void;
}

const EcommerceSupplierModal = ({
  setVisible,
  visible = false,
  id,
  updateTableData,
  setReload,
}: IEcommSupplierModel) => {
  const initialValues: IEcommSupplier = {
      date: new Date(),
      comments: "",
      supplierName: "",
      phone:0,
      gst:"",
      licenseNo:"",
      licenseExpiry:"",
      address: "",
  };

  const [formValues, setFormValues] = useState<IEcommSupplier>(initialValues);
  const [setRateOptions] = useState<any[]>([]);
  const [items, setItems] = useState<IEcommSupplier[]>([]);
  const { t,i18n } =useTranslation();

  const clearAll = () => {
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (id) {
      EcommSupplierGet(id)
        .then((res) => {
          if (res.data && res.data.data) {
              setFormValues(res.data.data)
              setItems(res.data.data.items || []);

          }
        })
        .catch((error) => {
          toast.error(t("Failed to load data."));
        });
    } else if (!id && visible) {
      setFormValues(initialValues);
    }
  }, [id,visible]);

  const handleSubmit = async (values: IEcommSupplier, formikHelpers: FormikHelpers<IEcommSupplier>) => {
    const res = id ? await EcommSupplierUpdate(values) : await EcommSupplierSave(values);
    toast.success(t("Success"));
    setVisible(false);
    formikHelpers.resetForm()
    setFormValues(initialValues)
    updateTableData()
    setReload(true)

};

  return (
    <div className="add-modal-container">
      <Dialog
        // header={id ? "Edit Supplier" : "Add Supplier"}
        header={<span style={{ fontWeight: 'bold' }}>{id ? t("Edit Supplier") : t("Add Supplier")}</span>}
        visible={visible}
        style={{ width: "500px", height: "700px" }}
        onHide={() => setVisible(false)}
        headerStyle={{ textAlign: "center" }}
        className="custom-dialog"
      >
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={supplierValidation}
        key={id || 'new'}
        onSubmit={(values, actions) => {
          console.log("Formik Submission Triggered:", values); // Debugging
          handleSubmit(values, actions);
        }}
      >
          {({ handleChange, values,errors,setFieldValue,touched }) => (
            <Form>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
            <div className="grid grid-cols-2 ">
              {/* Date Field */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label mt-5">
                  <Calendar
                    id="date"
                    value={values.date ? new Date(values.date) : null}
                    onChange={(e) => setFormValues({...values,date: e.value instanceof Date ? e.value : new Date()  })}
                    showIcon
                    dateFormat="dd/mm/yy"
                    style={{ height: '40px',width: '400px'}}
                  />
                  <label htmlFor="date">{t("Date")}</label>
                </span>
                {errors.date && touched.date && (
                   <ErrorMessage message={errors.date} id="date" />
                )}              
              </div>

              {/* License Expiry Date Field */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label mt-5">
                  <Calendar
                    id="licenseExpiry"
                    value={values.licenseExpiry ? new Date(values.licenseExpiry) : null}
                    onChange={(e) =>
                      setFieldValue("licenseExpiry", e.value)
                    }
                    showIcon
                    dateFormat="dd/mm/yy"
                    style={{ height: '40px'}}
                  />
                  <label htmlFor="licenseExpiry">{t("License Expiry")}</label>
                </span>
                {errors.licenseExpiry && touched.licenseExpiry && (
                   <ErrorMessage message={errors.licenseExpiry} id="licenseExpiry" />
                )} 
              </div>
              </div>
              {/* Name Fields */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="supplierName"
                    onChange={(e) => setFormValues({...values,supplierName: e.target.value })}
                    value={values.supplierName}
                    style={{height: '30px', width: '450px', fontSize: '16px', padding: '10px' }}
                  />
                  <label htmlFor="supplierName">{t("Supplier Name")}</label>
                </span>
                {errors.supplierName && touched.supplierName && (
                   <ErrorMessage message={errors.supplierName} id="supplierName" />
                )}
              </div>
          
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="phone"
                    value={values.phone ? values.phone.toString() : ""}
                    // onChange={(e) => setFormValues({ ...values, phone: e.value })}                    
                    onChange={handleChange}
                    style={{ height: '30px', width: '450px', fontSize: '16px', padding: '10px' }}                  />
                  <label htmlFor="phone">{t("Phone")}</label>
                </span>
                {errors.phone && touched.phone && (
                   <ErrorMessage message={errors.phone} id="phone" />
                )}
              </div>
          
              {/* Address Field */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="address"
                    onChange={handleChange}
                    value={values.address}
                    style={{ height: '30px',width: '450px', fontSize: '16px', padding: '10px' }}
                  />
                  <label htmlFor="address">{t("Address")}</label>
                </span>
                {errors.address && touched.address && (
                   <ErrorMessage message={errors.address} id="address" />
                )}
              </div>

              {/* Address Field */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="gst"
                    onChange={handleChange}
                    value={values.gst}
                    style={{ height: '30px',width: '450px', fontSize: '16px', padding: '10px' }}
                  />
                  <label htmlFor="gst">{t("GST")}</label>
                </span>
                {errors.gst && touched.gst && (
                   <ErrorMessage message={errors.gst} id="gst" />
                )}
              </div>
          
              {/* License No Field */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="licenseNo"
                    onChange={handleChange}
                    value={values.licenseNo}
                    style={{ height: '30px',width: '450px', fontSize: '16px', padding: '10px' }}                  />
                  <label htmlFor="licenseNo">{t("License No")}</label>
                </span>
                {errors.licenseNo && touched.licenseNo && (
                   <ErrorMessage message={errors.licenseNo} id="licenseNo" />
                )}
              </div>
          
              {/* Comments Field */}
              <div className="field col-12 md:col-6">
                <span className="p-float-label">
                  <InputText
                    id="comments"
                    value={values.comments}
                    onChange={handleChange}
                    style={{ width: '450px', fontSize: '16px', padding: '10px' }}                  
                  />
                  <label htmlFor="comments">{t("Comments")}</label>
                </span>
              </div>
          
              {/* Action Buttons */}
              <div className="flex justify-content-center mt-3">
                <Button
                  label={id ? t("Update") || "Update" : t("Save") || "Save"}
                  icon="pi pi-check"
                  type="submit"
                  className="p-button-success mr-2"
                  style={{ width: "120px" }}
                />
                <Button
                  label={t("Cancel") || "Cancel"}
                  icon="pi pi-times"
                  type="button"
                  onClick={() => setVisible(false)}
                  className="p-button-secondary mr-2"
                  style={{  width: "120px" }}
                />
              </div>
            </div>
          </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default EcommerceSupplierModal;
