import { ErrorMsg } from "../styledcomponents";

const ErrorMessage = ({ message, id }) => {
    return (
        <ErrorMsg id={id}>
            {message}
        </ErrorMsg>
    );
};

export default ErrorMessage;