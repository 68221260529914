import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import { IInterestRate } from "../../../../interfaces";
import { InterestRateSave } from "../../../../validation";
import { irSvc } from "../../../../services";
import { t } from "i18next";


const InterestRateModal = ({ setVisible, visible = false, viewOnly = true, id, setReload }: IInterestRateModal) => {
    const initial: IInterestRate = {
        borrow_Type: "",
        disburse_Type: "",
        emi_Interest: undefined,
        rate: undefined
    }
   
    const borrow_type = [{ label: 'Weekly', value: 'weekly' }, { label: 'Monthly', value: 'monthly' }];
    const disburse_type = [{ label: 'Full', value: 'full' }, { label: 'Reduced', value: 'reduced' }];
    const [initialValues, setInitialValues] = useState<IInterestRate>(initial)
    const [itemId, setItemId] = useState<string | null>(id)
    useEffect(() => {
        if (itemId) {
            irSvc.get(itemId).then(res => {
                setInitialValues(res.data);
            });
        }

    }, [itemId]);
    const onSubmit = async (values: IInterestRate, formikHelpers: FormikHelpers<IInterestRate>) => {
        const res = id ? await irSvc.update(values) : await irSvc.save(values);
        toast.success("Success");
        setVisible(false);
        formikHelpers.resetForm()
        setReload(true)

    };
    const onHide = () => {
        setInitialValues(initial)
        setItemId(null)
        setVisible(false)
    }
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <Button label={t("Cancel") + ''} icon="pi pi-times" className="p-button-danger p-button-raised" onClick={onHide} />
            <Button label={id ? t('Update') as string : t('Save') as string} icon="pi pi-check" style={{ background: '#11B3CF' }} className="p-button-success p-button-raised" type='submit' />
        </div>);
    return (
        <Dialog header={t("Interest Rate")} visible={visible} maximizable style={{ width: '50vw' }} onHide={onHide}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={InterestRateSave} onSubmit={onSubmit}>
                {
                    ({ values, errors, getFieldProps, touched, setFieldValue }) => (
                        <Form>
                            <div className="grid p-fluid mt-3">
                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label"> */}
                                    <label htmlFor="borrow_Type">{t("Borrow Type")}</label>
                                    <Dropdown {...getFieldProps(('borrow_Type'))} name='borrow_Type' options={borrow_type}
                                        id="borrow_Type" className="w-full " />
                                    {/* </span> */}
                                    {touched.borrow_Type && errors.borrow_Type && <ErrorMessage message={errors.borrow_Type} id="borrow_Type" />}
                                </div>

                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label"> */}
                                    <label htmlFor="disburse_Type">{t("Disburse Type")}</label>
                                    <Dropdown {...getFieldProps(('disburse_Type'))} name='disburse_Type' options={disburse_type}
                                        id="disburse_Type" className="w-full" />
                                    {/* </span> */}
                                    {touched.disburse_Type && errors.disburse_Type && <ErrorMessage message={errors.disburse_Type} id="disburse_Type" />}
                                </div>

                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label "> */}
                                    <label htmlFor="emi_Interest">{t("Emi Interest")}(%)</label>
                                    <InputNumber id="emi_Interest" minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('emi_Interest', e.value)} value={values.emi_Interest} name='emi_Interest' />
                                    {/* </span> */}
                                    {touched.emi_Interest && errors.emi_Interest && <ErrorMessage message={errors.emi_Interest} id="emi_Interest" />}
                                </div>

                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label"> */}
                                    <label htmlFor="rate">{t("Rate")}(%)</label>
                                    <InputNumber id="rate" onChange={(e) => setFieldValue('rate', e.value)} value={values.rate} name='rate' minFractionDigits={2} maxFractionDigits={2} />
                                    {/* </span> */}
                                    {touched.rate && errors.rate && <ErrorMessage message={errors.rate} id="rate" />}
                                </div>


                            </div>
                            {DialogFooter}
                        </Form>)
                }
            </Formik>

        </Dialog>
    );
};

export default InterestRateModal;



interface IInterestRateModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    setReload(val: boolean): void
}