import { useState, useEffect, useCallback, useRef } from "react";
import { Card, InputGroup } from "react-bootstrap";
import { Button } from "primereact/button";
import { t } from "i18next";
import DataGridV3 from "../../../../components/DataGridV3/DataGridV3";
import AddModal from "./addDriverModal";
import { toast } from "react-toastify";
import DriverViewModal from "./ViewDriverModal";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Driverlist , Driverget , DrivermultipleDelete } from "../../../../services/rentAdminPanelService/DriverService";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Paginator } from "primereact/paginator";


const Driver: React.FC = () => {
  const [visible, setDriverVisible] = useState(false);
  const [reload , setReload] = useState<boolean>(true)
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotal] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [selectSource, setSelectSource] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [visibleView, setVisibleView] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [values, setValues] = useState<any>("");
  const [driverDetails, setDriverDetails] = useState<any>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<any>("");
  const { t,i18n } =useTranslation();
  const inputRef = useRef<HTMLInputElement>(null)
  const [debouncedSearchName, setDebouncedSearchName] = useState('');
  
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setDebouncedSearchName(searchName);
  //   }, 100); // 500ms debounce delay

  //   return () => clearTimeout(timer);
  // }, [searchName]);

  const handleReload =() => {
    setSelectedDate(null)
    setSearchName("")
    setPage(1)
    setTableData([])
    setReload(true)
    getAllDriver(page, limit)
  };
  
  const onPageChange = (event: any) => {
    const newPage = event.page + 1; 
    const rowsPerPage = event.rows;
    setPage(newPage); 
    setLimit(rowsPerPage); 
    getAllDriver(newPage, rowsPerPage);
  };
   
  const getAllDriver = (page:number,limit:number) => {

    const filters: any = {
      date: selectedDate,
      first_name:searchName
    };
    Driverlist( (page).toString(),limit.toString(),filters).then((res: any) => {
      console.log("api...",res);
      const formattedData = res.data.res.map((item: any, index: number) => ({
        ...item,
        key: item.id || index,
        date: formatDateToDDMMYYYY(item.date), // Convert item.date to a Date object
        date_of_birth: formatDateToDDMMYYYY(item.date_of_birth), // Same for other date fields
        license_expiry: formatDateToDDMMYYYY(item.license_expiry), // Same for license_expiry
        first_name: item.first_name,
        
      }));
      setTableData(formattedData);
      setTotal(res.data.totalPages * limit);
      setReload(false);
    });
  };

  useEffect(() => {
    if (reload) {
      getAllDriver(page, limit)
      setReload(false)
      setId("")
    }
}, [reload]);

const details = async (id: string) => {
  try {
    const response = await Driverget(id);
    if (response && response.data && response.data.data) {
      const driverData = response.data.data;
      const formattedDriverData = {
        ...driverData,
        date: formatDateToDDMMYYYY(driverData.date),
        date_of_birth: formatDateToDDMMYYYY(driverData.date_of_birth),
        license_expiry: formatDateToDDMMYYYY(driverData.license_expiry),
      };
      setValues(formattedDriverData);
      setViewDetails(true);
    } else {
      console.log("No data found for this ID");
    }
  } catch (error) {
    console.error("Error fetching details:", error);
  }
};

function formatDateToDDMMYYYY(isoDate: string): string {
  let date = new Date(isoDate);
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

  useEffect(() => {
    if (values) {
      const driverDetails = {
        
        date: values.date || "N/A",
        first_name: values.first_name || "N/A",
        last_name: values.last_name || "N/A",
        middle_name: values.middle_name || "N/A",
        gender: values.gender || "N/A",
        phone_number: values.phone_number || "N/A",
        address: values.address || "N/A",
        date_of_birth: values.date_of_birth || "N/A",
        license_no: values.license_no || "N/A",
        license_expiry: values.license_expiry || "N/A",
      };
      setDriverDetails(driverDetails);
    }
  }, [values]);

  const deleteByIdList = (i: any) => {
    DrivermultipleDelete(i).then((res) => {
      if (res) {
        toast.success(t("Records deleted successfully"));
        getAllDriver(page, limit);
      } else {
        toast.error(t("Failed to delete records"));
      }
    });
  };

  const onCreateOrUpdate = (t: boolean, i: string) => {
    setId(i);
    setDriverVisible(t);
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage(parseInt(p, 10));
    setLimit(parseInt(l, 10));
    // getSerialNo();
  };
  

  useEffect(() => {
    getAllDriver(page, limit);
  }, [page, limit,selectedDate, searchName]);

  const columns = [
    { field: "driverSlNo", header: t("Driver ID") },
    { field: "date", header: t("Joining Date") },
    { field: "first_name", header: t("Driver Name") },
    { field: "phone_number", header: t("Phone Number") },
    { field: "license_no", header: t("License No") },
    { field: "address", header: t("Address") },
  ];

  // const sourceOptions = [
  //   { name: "Male", value: "male" },
  //   { name: "Female", value: "female" },
  //   { name: "Others", value: "others" }
  // ];

  const deleteDialogFooter = (
    <>
      <Button
        label={t("No") || "No"}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label={t("Yes") || "Yes"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteByIdList(id);
          setDeleteDialog(false);
        }}
      />
    </>
  );
  const maxLengthString = "100"; // This is a string
  const maxLengthNumber = Number(maxLengthString); // Convert it to a number

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchName]);

  const DriverFilter = () => {

    return (
      <div>
        <Button
          // label={t("Filter") as string}
          icon="pi pi-filter"
          tooltip={t("Filter") || "Filter"}
          onClick={() => setShowFilters(!showFilters)}
          className="mr-3 p-button-rounded"
          style={{ backgroundColor: "#11B3CF" }}
        />
        {showFilters && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Calendar
              placeholder={t("Date") as string}
              dateFormat="dd-mm-yy"
              showIcon
              value={selectedDate}
              className="mr-2"
              onChange={(e) => setSelectedDate(e.value)}
              style={{ height: "40px", width: "200px" }}
            />
            <InputText
              type="text"
              placeholder={t("First Name") as string}
              className="mr-2"
              value={searchName}
              onChange={(e: any) => setSearchName(e.target.value)}
              style={{ height: "40px" }}
              ref={inputRef}
            />
             {/* <InputGroup>
                <span className="p-input-icon-left bg-black">
                    <i className="pi pi-user" />
                    <InputText
                        value={searchName}
                        onInput={(e:any) =>setSearchName(e.target.value)}
                        placeholder={t("First Name") as string}
                        style={{ height: '2.5rem', width: '300px', borderRadius: '0px' }}
                    />
                </span>
                {/* <Button label={t("Search")} style={{ backgroundColor: '#11B3CF', height: '2.5rem' }} className="p-button-success" /> */}
            {/* </InputGroup> */}

        </div>
      )}
    </div>
  );
  };


  return (
    <>
      <div className="ml-auto flex justify-between gap-10">
          <h1>{t("Drivers")}</h1>
      </div>

      <DataGridV3
        tittle={t("")}
        data={tableData}
        columns={columns}
        totalRecords={totalRecords}
        paginator
        paginationProps={onPageChange}
        onViewDeatils={details}
        reload={false}
        filter={DriverFilter}
        setReload={handleReload}
        deleteByIdList={deleteByIdList}
        extraAction={() => {}}
        addNew={true}
        showActions={selectedRows.length > 0}
        setSelectedRows={setSelectedRows}
        onCreateOrEdit={onCreateOrUpdate}
      />
      <Paginator
        first={(page - 1) * limit} 
        rows={limit}
        totalRecords={totalRecords}
        rowsPerPageOptions={[2,5, 10, 20]} 
        onPageChange={onPageChange} 
      />
      <AddModal
        setVisible={setDriverVisible}
        visible={visible}
        updateTableData={() => setReload(true)}
        id={id}
        setReload={setReload}
      />

      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={t("Confirm")}
        modal
        onHide={() => setDeleteDialog(false)}
        footer={deleteDialogFooter}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {!id ? (
            <span>{t("Are you sure you want to delete the selected items?")}</span>
          ) : (
            <span>{t("Are you sure you want to delete?")}</span>
          )}
        </div>
      </Dialog>

      {viewDetails && (
        <DriverViewModal
          setVisible={setViewDetails}
          visible={viewDetails}
          details={driverDetails || {}}
          title={t("Driver Details")}
        />
      )}
    </>
  );
};

export default Driver;
