import { request } from 'http';
import { axiosInstance } from '../interceptors';
import IDriver from '../../interfaces/models/IDriver';

export const DriverSave = async (req: any) => {
    return await axiosInstance.post(`inventory/driver`, req);
};

export const Driverlist = async (page: string,limit: string, filter:any={}) => {
    return await axiosInstance.get(`inventory/driver/list`,{
      params: {
        page,
        limit,
        ...filter,
      }
    });
  };



export const Driverupdate = async ( request: IDriver) => {
    return await axiosInstance.put(`inventory/driver/update`,request);
};




// export const RateDelete = async (id:string) => {
//     return await axiosInstance.put(`inventory/driver/delete/${id}`);
// };

// export const getByIdVehicle = async (id:string) => {
//     return await axiosInstance.get(`inventory/vehicle/${id}`);
// };

export const Driverget= async (id:string) => {
    return await axiosInstance.get(`/inventory/driver/getById/${id}`);}


    export const DrivermultipleDelete = async (idHistory: string[] | number[]) => {
        console.log('IDs to delete:', idHistory); // Log the idHistory before sending the request
       
        try {
          const response = await axiosInstance.post(`/inventory/driver/multipleDelete`, idHistory);
          console.log("??????????????????????",idHistory);
          
          return response;
        } catch (error) {
          console.error('Error deleting multiple records:', error);
          throw error; // Re-throw the error to handle it elsewhere if needed
        }
      };