import { IInterestRate } from "../../interfaces";
import { axiosInstance } from '../interceptors';


export const save = async (request: IInterestRate) => {
    return await axiosInstance.post(`/master/interestRate`, request);
  };
  
  export const getAll = async () => {
    return await axiosInstance.get(`/master/interestRate/getAll`);
  };
  
  export const get = async (id:string) => {
    return await axiosInstance.get(`/master/interestRate/${id}`);
  };
  
  export const update = async (request: IInterestRate) => {
    return await axiosInstance.put(`/master/interestRate/update`,request)
  };
  