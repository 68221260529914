import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "primereact/button";
import { t } from "i18next";
import DataGridV3 from "../../../../components/DataGridV3/DataGridV3";
import AddModal from "./addmodal";
import { toast } from "react-toastify";
import VehicleViewModal from "./ViewRatesModal";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Ratelist,RateDelete,Rateget,Rateupdate,RatemultipleDelete } from "../../../../services/rentAdminPanelService/RateService";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { Paginator } from "primereact/paginator";


const Vehicle: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [reload, setReload] = useState<boolean>(true)
  const [totalRecords, setTotal] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [selectSource, setSelectSource] = useState<any>("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [visibleView, setVisibleView] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [values, setValues] = useState<any>("");
  const [RateDetails, setRateDetails] = useState<any>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const { t,i18n } =useTranslation();

  const handleReload = () => {
    setSelectedDate(null)
    setPage(1)
    setTableData([]);
    setReload(true)
    getSerialNo(page, limit)
  };

  const onPageChange = (event: any) => {
    const newPage = event.page + 1; 
    const rowsPerPage = event.rows;
    setPage(newPage); 
    setLimit(rowsPerPage); 
    getSerialNo(newPage, rowsPerPage);
  };
  
  const getSerialNo = async (page:number,limit:number) => {
    const filters:any = {
      date: selectedDate ? formatDateToDDMMYYYY(selectedDate) : "",
    }
    
    await Ratelist((page).toString(),limit.toString(),filters).then((res: any) => {
      const formattedData = res.data.res.map((item: any, index: number) => ({
        ...item,
        key: item.id || index,
        date: formatDateToDDMMYYYY(item.date),
        pollution_expiry: formatDateToDDMMYYYY(item.pollution_expiry),
        insurance_expiry: formatDateToDDMMYYYY(item.insurance_expiry),
      }));
      setTableData(formattedData);
      setTotal(res.data.totalPages * limit);
      setReload(false);
    });
  };

  const details = async (id: string) => {
    try {
      const response = await Rateget(id);
      if (response && response.data && response.data.data) {
        const RateData = response.data.data;
        const formattedRateDate = {
          ...RateData,
          date: formatDateToDDMMYYYY(RateData.date),
        }
        setValues(formattedRateDate);
        setViewDetails(true);
      } else {
        console.log("No data found for this ID");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const deleteByIdList = (i: any) => {
    RatemultipleDelete(i).then((res) => {
      if (res) {
        toast.success(t("Records deleted successfully"));
        getSerialNo(page, limit);
      } else {
        toast.error(t("Failed to delete records"));
      }
    });
  };

  const onCreateOrUpdate = (t: boolean, i: string) => {
    setId(i);
    setVisible(t);
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage(parseInt(p, 10));
    setLimit(parseInt(l, 10));
    getSerialNo(page, limit);
  };

  function formatDateToDDMMYYYY(isoDate: string): string {
    let date = new Date(isoDate);
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    getSerialNo(page, limit);
  }, [page, limit,selectedDate]);

  useEffect(() => {
    if (reload) {
      getSerialNo(page, limit)
      setReload(false)
      setId("")
    }
}, [reload])

  useEffect(() => {
    if (values) {
      const RateDetails = {
        date: values.date || "N/A",
        vehicleMake: values.vehicleMake || "N/A",
        vehicleId: values.vehicleId || "N/A",
        registrationNo: values.registration_number || "N/A",
        baseRate: values.baseRate || "N/A",
        kmRate: values.kmRate || "N/A",
        maxKmPerDay: values.maxKmPerDay || "N/A",
        comments: values.comments || "N/A",

      };
      setRateDetails(RateDetails);
    }
  }, [values]);

  const handleView = (vehicleId: string) => {
    setSelectedVehicleId(vehicleId);
    setVisibleView(true);
  };

  const columns = [
    { field: "vehicleRateSlNo", header: t("Sl No")},
    { field: "date", header: t("Created Date") },
    { field: "vehicleMake", header: t("Vehicle Make") },  // Corresponds to vehicleMake
    { field: "vehicleId", header: t("Vehicle ID") },      // Corresponds to vehicleId
    { field: "baseRate", header: t("Base Rate") },        // Corresponds to baseRate
    { field: "kmRate", header: t("KM Rate") },            // Corresponds to kmRate
    { field: "maxKmPerDay", header: t("Max KM/Day") },    // Corresponds to maxKmPerDay
    // { field: "comments", header: "Comments" },         // Corresponds to comments
  ];
  

  const sourceOptions = [
    { name: t("SUV"), code: "SUV" },
    { name: t("SEDAN"), code: "SEDAN" },
  ];

  const deleteDialogFooter = (
    <>
      <Button
        label={t("No") || "No"}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label={t("Yes") || "Yes"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteByIdList(id);
          setDeleteDialog(false);
        }}
      />
    </>
  );

  const rateFilter = () => (
    <div>
      <Button
        // label={t("Filter") as string}
        icon="pi pi-filter"
        tooltip={t("Filter") || "Filter"}
        onClick={() => setShowFilters(!showFilters)}
        className="mr-3 p-button-rounded"
        style={{ backgroundColor: "#11B3CF" }}
      />
      {showFilters && (
        <div style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "10px" }}>
            <Calendar
              placeholder={t("Date") as string}
              dateFormat="dd-mm-yy"
              showIcon
              value={selectedDate}
              className="mr-2"
              onChange={(e) => setSelectedDate(e.value)}
            />
          
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className="ml-auto flex justify-between gap-10">
          <h1>{t("Rates")}</h1>
      </div>

      <DataGridV3
        tittle={t("")}
        data={tableData}
        columns={columns}
        paginator
        totalRecords={totalRecords}
        paginationProps={onPageChange}
        onViewDeatils={details}
        reload={false}
        filter={rateFilter}
        setReload={handleReload}
        deleteByIdList={deleteByIdList}
        extraAction={() => {}}
        addNew={true}
        showActions={selectedRows.length > 0}
        setSelectedRows={setSelectedRows}
        onCreateOrEdit={onCreateOrUpdate}
      />
      <Paginator
        first={(page - 1) * limit} 
        rows={limit}
        totalRecords={totalRecords}
        rowsPerPageOptions={[2,5, 10, 20]} 
        onPageChange={onPageChange} 
      />
      <AddModal
        setVisible={setVisible}
        visible={visible}
        updateTableData={() => setReload(true)}
        id={id}
        setReload={setReload} 
      />

      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={t("Confirm")}
        modal
        onHide={() => setDeleteDialog(false)}
        footer={deleteDialogFooter}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {!id ? (
            <span>{t("Are you sure you want to delete the selected items?")}</span>
          ) : (
            <span>{t("Are you sure you want to delete?")}</span>
          )}
        </div>
      </Dialog>

      {viewDetails && (
        <VehicleViewModal
          setVisible={setViewDetails}
          visible={viewDetails}
          details={RateDetails || {}}
          title={t("Vehicle Details")}
        />
      )}
    </>
  );
};

export default Vehicle;
