import React, { useState, useEffect } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import {
  DriverSave,
  Driverupdate,
  Driverget,
} from "../../../../services/rentAdminPanelService/DriverService";
import IDriver from "../../../../interfaces/models/IDriver";
import { driverSave } from "../../../../validation/adminPanelValidation/driver";
import { useTranslation } from "react-i18next";

interface IVehicleModel {
  visible: boolean;
  setVisible(val: boolean): void;
  id: string | undefined;
  updateTableData: () => void;
  setReload(val:boolean):void
}

export default function AddVehicleModal({
  setVisible,
  visible = false,
  id,
  updateTableData,
  setReload
}: IVehicleModel) {
  const initialValues: IDriver = {
    date: new Date,
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    phone_number: "",
    address: "",
    date_of_birth: "",
    license_no: "",
    license_expiry: "",
  };

  const [formValues, setFormValues] = useState<IDriver>(initialValues);
  const { t,i18n } =useTranslation();

  useEffect(() => {
    if (id) {
      Driverget(id)
        .then((res) => {
          if (res.data && res.data.data) {
            // Convert date fields to Date objects
            const driverData = res.data.data;
            setFormValues({
              ...driverData,
              date: driverData.date ? new Date(driverData.date) : "",
              date_of_birth: driverData.date_of_birth ? new Date(driverData.date_of_birth) : "",
              license_expiry: driverData.license_expiry ? new Date(driverData.license_expiry) : "",
            });
          }
        })
        .catch(() => {
          toast.error(t("Failed to load data."));
        });
    } else if (!id && visible) {
      setFormValues(initialValues);
    }
  }, [id, visible]);
  

  const onClose = () => {
    setFormValues(initialValues);
    setVisible(false);
  };

  const onSubmit = async (values: IDriver, formikHelpers: FormikHelpers<IDriver>) => {
    try {
      const res = id ? await Driverupdate(values) : await DriverSave(values);
      toast.success(t("Success"));
      setVisible(false);
      formikHelpers.resetForm();
      updateTableData();
      setFormValues(initialValues);
      setReload(true)
    } catch (error) {
      toast.error(t("Failed to save data."));
    }
  };

  return (
    <Dialog
      header={<span style={{ fontWeight: 'bold' }}>{id ? t("Edit Driver") : t("Add Driver")}</span>}
      visible={visible}
      style={{ width: "60vw" }}
      headerStyle={{ textAlign: "center", color: "black" }}
      onHide={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={driverSave}
        onSubmit={(values, actions) => {
          console.log("Form Submitted:", values); // Debugging the form submission
          onSubmit(values, actions);
        }}
      >
        {({ isSubmitting,values,handleSubmit, handleChange, setFieldValue, errors, touched }) => (
          <Form>
            <div className="grid p-fluid">
              {/* Date Field */}
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Calendar
                    id="date"
                    value={values.date }
                    onChange={(e) => setFieldValue("date", e.value)}
                    showIcon
                    dateFormat="dd/mm/yy"
                  />
                  <label htmlFor="date">{t("Date")}</label>
                </span>
                <ErrorMessage message={errors.date} id="date-error" />
              </div>
            </div>

            <div className="p-fluid grid">
              {/* Name Fields */}
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="first_name">{t("First Name")}</label>
                </span>
                <ErrorMessage
                  message={errors.first_name}
                  id="first_name-error"
                />
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="middle_name"
                    value={values.middle_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="middle_name">{t("Middle Name")}</label>
                </span>
                <ErrorMessage
                  message={errors.middle_name}
                  id="middle_name-error"
                />
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  <label htmlFor="last_name">{t("Last Name")}</label>
                </span>
                <ErrorMessage
                  message={errors.last_name}
                  id="last_name-error"
                />
              </div>

              {/* Other Details */}
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="gender"
                    value={values.gender}
                    onChange={handleChange}
                  />
                  <label htmlFor="gender">{t("Gender")}</label>
                </span>
                <ErrorMessage
                  message={errors.gender}
                  id="gender-error"
                />
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="phone_number"
                    value={values.phone_number}
                    onChange={handleChange}
                  />
                  <label htmlFor="phone_number">{t("Phone Number")}</label>
                </span>
                <ErrorMessage
                  message={errors.phone_number}
                  id="phone_number-error"
                />
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="address"
                    value={values.address}
                    onChange={handleChange}
                  />
                  <label htmlFor="address">{t("Address")}</label>
                </span>
                <ErrorMessage
                  message={errors.address}
                  id="address-error"
                />
              </div>

              {/* Date of Birth, License Details */}
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Calendar
                    id="date_of_birth"
                    value={values.date_of_birth}
                    onChange={(e) =>
                      setFieldValue("date_of_birth", e.value)
                    }
                    showIcon
                  />
                  <label htmlFor="date_of_birth">{t("Date of Birth")}</label>
                </span>
                <ErrorMessage
                  message={errors.date_of_birth}
                  id="date_of_birth-error"
                />
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <InputText
                    id="license_no"
                    value={values.license_no}
                    onChange={handleChange}
                  />
                  <label htmlFor="license_no">{t("License No")}</label>
                </span>
                <ErrorMessage
                  message={errors.license_no}
                  id="license_no-error"
                />
              </div>
              <div className="field col-12 md:col-4">
                <span className="p-float-label">
                  <Calendar
                    id="license_expiry"
                    value={values.license_expiry}
                    onChange={(e) =>
                      setFieldValue("license_expiry", e.value)
                    }
                    showIcon
                  />
                  <label htmlFor="license_expiry">{t("License Expiry")}</label>
                </span>
                <ErrorMessage
                  message={errors.license_expiry}
                  id="license_no-error"
                />
              </div>
            </div>

            {/* File Upload
            <div className="grid">
              <div className="field col-12">
                <FileUpload
                  name="documents"
                  url="/upload"
                  mode="basic"
                  accept=".pdf,.doc,.docx,.jpg,.png"
                  maxFileSize={1000000}
                  onUpload={(e) => console.log("File uploaded", e.files)}
                  chooseLabel="Upload Documents"
                />
              </div>
            </div> */}

            {/* Dialog Footer */}
            <div className="flex justify-content-between gap-3 mt-4">
              <div className="flex-1">
                <FileUpload
                  name="documents"
                  url="/upload"
                  mode="basic"
                  accept=".pdf,.doc,.docx,.jpg,.png"
                  maxFileSize={1000000}
                  onUpload={(e) => console.log("File uploaded", e.files)}
                  chooseLabel={t("Upload Documents") || "Upload Documents"}
                />
              </div>

              <div className="flex gap-3">
                <Button
                  label={id ? t("Update") || "Update" : t("Save") || "Save"}
                  icon="pi pi-check"
                  className="p-button-success"
                  type="submit"
                />
                <Button 
                type="button"
                label={t("Cancel") || "Cancel"} 
                onClick={() => setVisible(false)}
                />
              </div>
            </div>

          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
