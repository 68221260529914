import * as Yup from 'yup';

export const supplierValidation = Yup.object().shape({
  date: Yup.date()
    .required('Date is required')
    .typeError('Invalid date format'),
  
  comments: Yup.string()
    .max(500, 'Comments cannot exceed 500 characters'),
  
  supplierName: Yup.string()
    .required('Supplier name is required')
    .min(2, 'Supplier name must be at least 2 characters')
    .max(100, 'Supplier name cannot exceed 100 characters'),
  
  phone: Yup.string()
    .required('Phone number is Required')
    .matches(
    /^\+?\d{10,13}$/,'Phone number must be exactly 10 digits and may optionally start with a "+" followed by the country code'),
    
  // gst: Yup.string()
    // .required('GST number is required'),
    // .matches(/^[a-zA-Z0-9]{15}$/, 'GST number must be 15 alphanumeric characters'),
  
  // licenseNo: Yup.string()
    // .required('License number is required')
    // .max(20, 'License number cannot exceed 20 characters'),
  
  // licenseExpiry: Yup.date()
    // .required('License expiry date is required')
    // .typeError('Invalid date format')
    // .min(new Date(), 'License expiry date must be in the future'),
  
  address: Yup.string()
    .required('Address is required')
    .min(5, 'Address must be at least 5 characters')
    .max(200, 'Address cannot exceed 200 characters'),
});
