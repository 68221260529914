import * as Yup from 'yup';

export const loanSettings = Yup.object().shape({
    borrow_type: Yup.string().required('Borrow type is required'),
    day: Yup.mixed().when('borrow_type', {
        is: 'weekly',
        then: Yup.string().required('Day is required'),
        otherwise: Yup.string().required('Day is required'),
    }),
    maximum_buzzer: Yup.number().required('Maximum buzzer is required')
    .typeError('Maximum buzzer is required'),
});