import { request } from 'http';
import { axiosInstance } from '../interceptors';
import IEcommRate from '../../interfaces/models/IEcommRate';

export const EcommRateSave = async (req: any) => {
    return await axiosInstance.post(`inventory/ecommRate`, req);
};

export const EcommRatelist = async ( page: string,limit: string,filter:any={}) => {
    return await axiosInstance.get(`inventory/ecommRate/list`, {
        params: {
          page,
          limit,
          ...filter,
        }
      });
    };

export const EcommRateupdate = async ( request: any) => {
    return await axiosInstance.put(`inventory/ecommRate/update`,request);
};




export const EcommRateDelete = async (id:string) => {
    return await axiosInstance.put(`inventory/delete/${id}`);
};

// export const getByIdVehicle = async (id:string) => {
//     return await axiosInstance.get(`inventory/vehicle/${id}`);
// };

export const EcommRateget= async (id:string) => {
    return await axiosInstance.get(`/inventory/ecommRate/getById/${id}`);}


    export const EcommRatemultipleDelete = async (idHistory: string[] | number[]) => {
        console.log('IDs to delete:', idHistory); // Log the idHistory before sending the request
       
        try {
          const response = await axiosInstance.post(`/inventory/ecommRate/multipleDelete`, idHistory);
          console.log("??????????????????????",idHistory);
          
          return response;
        } catch (error) {
          console.error('Error deleting multiple records:', error);
          throw error; // Re-throw the error to handle it elsewhere if needed
        }
      };