import React, { useState , useEffect} from 'react';
import { t } from "i18next";
import DataGridV3 from "../../../../../components/DataGridV3/DataGridV3";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import EcommerceSupplierModal from './ecommerceSupplierModal';
import { Button } from "primereact/button"; // Ensure you're using PrimeReact Button component
import './ecommerceSupplierModal.css';
import { EcommSupplierMultipleDelete, EcommSupplierList, EcommSupplierGet  } from "../../../../../services/ecommAdminPanelService/ecommSupplierService";
import { privateDecrypt } from 'crypto';
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import EcommSupplierViewModal from './ecommSupplierViewModal';
import { useTranslation } from "react-i18next";
import { Paginator } from 'primereact/paginator';


// Typing for the state
interface SourceOption {
  name: string;
  value: string;
}

const EcommerceSupplier: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]); // Update type as needed
  const [reload, setReload] = useState<boolean>(true);
  const [totalRecords, setTotal] = useState<number>(0);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectSource, setSelectSource] = useState<string>(""); // Better to type as string or specific value
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [visibleView, setVisibleView] = useState<boolean>(false);
  const [id, setId] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<SourceOption[]>([]);
  const [allDataFetched,setAllDataFetched] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [values, setValues] = useState<any>("");
  const [EcommDetails, setEcommDetails] = useState<any>(null);
  const [selectedEcommRateId, setSelectedEcommRateId] = useState<string | null>(null);
  const { t,i18n } =useTranslation();

  const onCreateOrUpdate = (t: boolean, i: string) => {
    setId(i);   
    setVisible(t);
    setReload(true);
  };

  const onPageChange = (event: any) => {
    const newPage = event.page + 1; 
    const rowsPerPage = event.rows;
    setPage(newPage); 
    setLimit(rowsPerPage); 
    getAllData(newPage, rowsPerPage);
  };
  // useEffect(() => {
  //   setTableData([]);  
  //   setPage(1);  
  //   setAllDataFetched(false);  
  // }, [selectedPrice,selectedType, selectedDate]);

  function formatDateToDDMMYYYY(isoDate: string): string {
    let date = new Date(isoDate);
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const deleteByIdList = (i: any) => {
    EcommSupplierMultipleDelete(i).then((res) => {
      if (res) {
        toast.success(t("Records deleted successfully"));
        // getSerialNo();
        setReload(true);
      } else {
        toast.error(t("Failed to delete records"));
      }
    });
  };


  const getAllData = (page:number,limit:number) => {
    const filters: any = {
      date: selectedDate,
    };
    EcommSupplierList((page).toString(),limit.toString(),filters).then((res: any) => {
      const formattedData = res.data.res.map((item: any, index: number) => ({
        ...item,
        key: item.id || index,
        type:item.type,
        date: formatDateToDDMMYYYY(item.date),
        licenseExpiry: formatDateToDDMMYYYY(item.licenseExpiry), // Same for license_expiry

      }));
      
      setTableData(formattedData);
      setTotal(res.data.totalPages * limit);
      setReload(false)
    });
  };

  const details = async (id: string) => {
    try {
      const response = await EcommSupplierGet(id);
      if (response && response.data && response.data.data) {
        const EcommerceData = response.data.data;
        const formattedEcommerceData = {
          ...EcommerceData,
          date: formatDateToDDMMYYYY(EcommerceData.date),
          licenseExpiry: formatDateToDDMMYYYY(EcommerceData.licenseExpiry)
        };
        setValues(formattedEcommerceData);        
        setViewDetails(true);
      } else {
        console.log("No data found for this ID");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    if (reload) {
      getAllData(page, limit);
    }
  }, [reload]);

  useEffect(() => {
    getAllData(page, limit);
  }, [page, limit,selectedDate]);

  const getPaginationProps = (p: string, l: string) => {
    setPage(parseInt(p, 10));
    setLimit(parseInt(l, 10));
  };
  const columns = [
    { field: "suppSlNo", header: t("Supplier ID")},
    { field: "date", header: t("Date") },
    { field: "supplierName", header: t("Supplier Name") },
    { field: "phone", header: t("Phone")},
    { field: "gst", header: t("GST")},
    { field: "licenseNo", header: t("License No")},
    { field: "licenseExpiry", header:t("License Expiry")},
    // { field: "address", header:t("Address")}
  ];

  useEffect(() => {
    if(values) {

      const EcommerceSupplierDetails = {
        date:values.date || "N/A",
        supplier_Name:values.supplierName || "N/A",
        phone:values.phone || "N/A",
        gst:values.gst || "N/A",
        license_No:values.licenseNo || "N/A",
        license_Expiry:values.licenseExpiry || "N/A",
        address:values.address || "N/A",
        comments:values.comments || "N/A"
      };
      setEcommDetails(EcommerceSupplierDetails);
    }
  },[values]
);

const handleView = (rateId: string) => {
  setSelectedEcommRateId(rateId);
  setVisibleView(true);
};

  const deleteDialogFooter = (
    <>
      <Button
        label={t("No") || "No"}
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteDialog(false)}
      />
      <Button
        label={t("Yes") || "Yes"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          deleteByIdList(id);
          setDeleteDialog(false);
        }}
      />
    </>
  );

  const handleReload =() => {
    setSelectedDate(null)
    setPage(1)
    setTableData([])
    setReload(true)
    getAllData(page, limit)
  };
    
  const EcommFilter = () => {
    return (
      <div>
        <Button
          icon="pi pi-filter"
          tooltip={t("Filter") || "Filter"}
          onClick={() => setShowFilters(!showFilters)}
          className="mr-3 p-button-rounded"
          style={{ backgroundColor: "#11B3CF" }}
        />
        {showFilters && (
          <div style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "10px" }}>
            <Calendar
              placeholder={t("Date") as string}
              dateFormat="dd-mm-yy"
              showIcon
              value={selectedDate}
              className="mr-2"
              onChange={(e) => setSelectedDate(e.value)}
            />
          </div>
        )}
      </div>
    );
  };
  

  return (
    <>
      <div className='ml-auto flex justify-between gap-10'>
        <h1>{t("Supplier")}</h1>
      </div>

      <DataGridV3
        tittle={t("")}
        data={tableData}
        columns={columns}
        paginator
        totalRecords={totalRecords}
        paginationProps={onPageChange}
        onViewDeatils={details}
        reload={false}
        filter={EcommFilter}
        setReload={handleReload}
        deleteByIdList={deleteByIdList}
        extraAction={() => {}}
        addNew={true}
        showActions={selectedRows.length > 0}
        setSelectedRows={setSelectedRows}
        onCreateOrEdit={onCreateOrUpdate}
      />

      <Paginator
        first={(page - 1) * limit} 
        rows={limit}
        totalRecords={totalRecords}
        rowsPerPageOptions={[2,5, 10, 20]} 
        onPageChange={onPageChange} 
      />
      <EcommerceSupplierModal
        setVisible={setVisible}
        visible={visible}
        updateTableData={() => setReload(true) } // Temporary empty function or implement
        setReload={(val: boolean) => setReload(val)} // Temporary empty function or implement
        id={id}     
      />

      <Dialog
        visible={deleteDialog}
        style={{ width: "450px" }}
        header={t("Confirm")}
        modal
        onHide={() => setDeleteDialog(false)}
        footer={deleteDialogFooter}
      >
        <div className="flex align-items-center justify-content-center">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {!id ? (
            <span>{t("Are you sure you want to delete the selected items?")}</span>
          ) : (
            <span>{t("Are you sure you want to delete?")}</span>
          )}
        </div>
      </Dialog>

      {viewDetails && (
        <EcommSupplierViewModal
          setVisible={setViewDetails}
          visible={viewDetails}
          details={EcommDetails || {}}
          title={t("Supplier Details")}
        />
      )}
    </>
  );
}
export default EcommerceSupplier;
